import { ReactNode, useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import logger from '../utils/logger';
import { Container, Drawer, IconButton, Stack, Link, Box, Grid, Collapse, Switch, FormControlLabel, Button, ButtonGroup, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DataGrid } from '../components/dataGrid/DataGrid';
import { useLoaderData, Link as RouterLink } from 'react-router-dom';
import { Inspection } from '../entities/Inspection';
import CancelIcon from '@mui/icons-material/Cancel';
import useAxios from 'hooks/useAxios';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import SubCard from 'components/cards/SubCard';
import { InspectionForm } from 'components/forms/InspectionForm';
import { InspectionResult } from 'entities';
import { Sensors, SensorsOff, Visibility, VisibilityOff } from '@mui/icons-material';
import { InspectionResultForm } from 'components/forms/InspectionResultForm';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import useConfirm from 'hooks/useConfirm';
import MapControl from 'components/maps/MapControl';
import { MarkerType } from 'components/maps/MapMarker';
import useInspection from 'hooks/useInspection';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import { InspectionState } from 'entities/enums/InspectionState';
import { MapSearchControl } from 'components/maps/MapSearchControl';

export type InspectionResultType = 'Signal' | 'Visibility';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export default function InspectionView() {
  const { get, patch } = useAxios();
  const [id, setId] = useState<number>();
  // const { inspectionEvents } = InspectionConnector();
  const params = useLoaderData() as string;
  const { getConfirmation, ConfirmDialog } = useConfirm();
  // const [loading, setIsLoading] = useState(true);

  const [name, setName] = useState('');
  // const [inspection, setInspection] = useState<Inspection>();

  // const [devices, setDevices] = useState<Device[]>();
  const [reload, setReload] = useState(false);
  const [dataQueryUrl, setDataQueryUrl] = useState<string>();
  const [results, setResults] = useState<InspectionResult[]>();

  const { inspection, getInspection } = useInspection();

  const [showDetails, setShowDetails] = useState(false);

  const [show, setShow] = useState<MarkerType>('Uninspected');

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState<{ result: InspectionResult; type: InspectionResultType }>();

  const [component, setComponent] = useState<string | null>('table');

  const handleToggle = (event: React.MouseEvent<HTMLElement>, component: string | null) => {
    if (component) setComponent(component);
  };

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    logger.log(event);
    if (event && event.type === 'keydown' && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const RenderActionHeader = (): ReactNode => {
    const { handleState } = useInspection();
    // const handleState = async () => {
    //   // console.log('HANDLESTATE', inspection);
    //   if (inspection && !inspection.endDate) {
    //     const alternateState = inspection.state === InspectionState.Paused ? InspectionState.InProgress : InspectionState.Paused;
    //     // const result = await getConfirmation(`Are you sure you want to ${alternateState === InspectionState.InProgress ? 'RESUME' : 'PAUSE'} this inspection`);
    //     // if (result)
    //     await patch(`/api/inspection/${inspection.id}/state/${alternateState}`);
    //     //handleInspectionSubmit({ ...inspection, state: alternateState });
    //   }
    // };
    const handleComplete = async () => {
      if (inspection) {
        const result = await getConfirmation('Are you sure you want to complete this inspection');
        if (result) handleInspectionSubmit({ ...inspection!, state: InspectionState.Completed, endDate: new Date(Date.now()).toISOString() });
      }
    };

    if (inspection?.state === InspectionState.Completed) return <></>;

    return (
      inspection && (
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Button variant='contained' onClick={() => handleState(inspection.id)}>
            {inspection?.state === InspectionState.InProgress ? 'PAUSE' : 'RESUME'}
          </Button>
          <Button variant='outlined' onClick={handleComplete}>
            MARK COMPLETE
          </Button>
        </Box>
      )
    );
  };

  const RenderActionCell = (params: GridRenderCellParams<InspectionResult, any, any, GridTreeNodeWithRender>, type: InspectionResultType): ReactNode => {
    const outcome = type === 'Signal' ? params.row.outcomeSignals : params.row.outcomeVisibility;
    if (params.row && outcome !== null && outcome !== undefined) {
      const passed = outcome === InspectionOutcome.PASS;
      // console.log(`${type}[${params.row.id}]: ${outcome}=${passed}`);
      return (
        <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
          <IconButton
            color={passed ? 'success' : 'error'}
            sx={{ width: 25, height: 25, ':hover': { cursor: 'default', backgroundColor: 'transparent' } }}
            title={`${type} check ${passed ? 'passed' : 'failed'}`}
          >
            {type === 'Signal' ? (
              <Sensors sx={{ width: 20, height: 20, fill: passed ? '#6cc067' : '#d9534' }} />
            ) : (
              <Visibility sx={{ width: 20, height: 20, fill: passed ? '#6cc067' : '#d9534' }} />
            )}
          </IconButton>
        </Stack>
      );
    }

    const handlePassed = () => {
      type === 'Signal'
        ? handleInspectionResultSubmit({ ...params.row!!, outcomeSignals: InspectionOutcome.PASS, commentSignals: '' })
        : handleInspectionResultSubmit({ ...params.row!!, outcomeVisibility: InspectionOutcome.PASS, commentVisibility: '' });
    };

    const handleFailed = () => {
      setDrawerData({ result: params.row, type });
      setDrawerOpen(true);
    };

    return (
      <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
        <IconButton color='success' sx={{ width: 25, height: 25 }} title={`${type} check passed?`} onClick={() => handlePassed()}>
          {type === 'Signal' ? <Sensors sx={{ width: 20, height: 20 }} /> : <Visibility sx={{ width: 20, height: 20 }} />}
        </IconButton>
        <IconButton color='error' sx={{ width: 25, height: 25 }} title={`${type} check failed?`} onClick={() => handleFailed()}>
          {type === 'Signal' ? <SensorsOff sx={{ width: 20, height: 20 }} /> : <VisibilityOff sx={{ width: 20, height: 20 }} />}
        </IconButton>
      </Stack>
    );
  };

  const handleInspectionSubmit = (data: Inspection) => {
    //TODO implement formatter
    data.type = undefined;
    data.results = []; // data.results?.map((r) => ({ ...r, device: undefined, signals: [] }));
    console.log('SUBMITTING', data);
    patch(`/api/inspection/${id}`, data).then(() => {
      setDrawerData(undefined);
      // get<Inspection>(`/api/inspection/${id}`).then(setInspection);
    });
  };

  const handleInspectionResultSubmit = (data: InspectionResult) => {
    data.device = undefined;
    data.signals = [];
    console.log('SUBMITTING', data);
    patch(`/api/inspectionresult/${id}`, data).then(() => {
      setDrawerData(undefined);
      getInspection(id);
      // get<Inspection>(`/api/inspection/${id}`).then(setInspection);
    });
    setDrawerData(undefined);
  };

  const columns: GridColDef<InspectionResult>[] = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'outcomeVisibility',
      headerName: 'Visible',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderActionCell(params, 'Visibility');
      },
    },
    {
      field: 'outcomeSignals',
      headerName: 'Signals',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderActionCell(params, 'Signal');
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      filterable: false,
      renderCell(params) {
        return params.value ? new Date(params.value).toLocaleString() : '';
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      filterable: false,
      renderCell(params) {
        return <div title={params.row.device?.type ?? ''}>{params.row.device?.type ?? ''}</div>;
      },
    },
    {
      field: 'name',
      headerName: 'Device',
      width: 400,
      filterable: false,
      renderCell(params) {
        return (
          <Link component={RouterLink} to={`/device/${params.row.deviceID}`}>
            {params.row.device?.name.toString().replaceAll('_', ' ')}
          </Link>
        );
      },
    },
    {
      field: 'signals',
      headerName: 'Signals',
      width: 400,
      filterable: false,
      renderCell(params) {
        const signals = params.row.signals?.map((s) => s.signalType?.name ?? s.type).join(', ') ?? '';
        return <div title={signals}>{signals}</div>;
      },
    },

    // {
    //   field: 'description',
    //   headerName: 'Description',
    //   width: 400,
    //   filterable: false,
    //   renderCell(params) {
    //     return params.row.device?.description ?? '';
    //   },
    // },
    // {
    //   field: 'location',
    //   headerName: 'Location',
    //   width: 500,
    //   filterable: false,
    //   renderCell(params) {
    //     return params.row.device?.location?.name ?? '';
    //   },
    // },
  ];

  useEffect(() => {
    if (id !== undefined) {
      if (id > 0) getInspection(id);
      else setShowDetails(true);
    }
  }, [id]);

  useEffect(() => {
    if (inspection) setName(inspection.name);
  }, [inspection]);

  useEffect(() => {
    if (params && !isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  useEffect(() => {
    if (inspection) {
      setDataQueryUrl(`/api/inspectionresult/query/${inspection.id}/${show}`);
      setReload(!reload);
    }
    // renderResults(inspection?.results ?? []);
  }, [show, inspection]);

  // useEffect(() => {
  //   console.log('INSPECTION UPDATED');
  //   inspectionEvents((i) => get<Inspection>(`/api/inspection/${i.id}`).then(setInspection));
  // }, [inspectionEvents]);

  useEffect(() => {
    if (drawerData) setDrawerOpen(true);
    else setDrawerOpen(false);
  }, [drawerData]);

  return (
    <>
      <ConfirmDialog />
      {/* <ConfirmDialog text={dialogText} open={dialogOpen} setOpen={setDialogOpen} onConfirm={dialogCallback} /> */}
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <MainCard
            title={inspection ? `${name} [ ${InspectionState[inspection?.state ?? 0].toUpperCase()} ]` : 'New Inspection'}
            secondary={RenderActionHeader()}
            contentSX={{ display: 'flex', flexDirection: 'column', gap: 5 }}
          >
            <Collapse in={showDetails} collapsedSize={72}>
              <SubCard
                title={'Inspection Details'}
                secondary={<FormControlLabel control={<Switch checked={showDetails} onChange={(e) => setShowDetails(e.target.checked)} />} label={showDetails ? 'Hide' : 'Show'} />}
              >
                <InspectionForm readOnly={inspection !== undefined} entity={inspection} />
              </SubCard>
            </Collapse>

            {inspection && (
              <Box>
                <Grid container mb={5}>
                  {component === 'table' && (
                    <Grid item xs={12} md={8} mt={2}>
                      <ButtonGroup variant='outlined'>
                        <Button variant={show === 'Uninspected' ? 'contained' : 'outlined'} onClick={() => setShow('Uninspected')}>
                          Pending
                        </Button>
                        <Button variant={show === 'Inspected' ? 'contained' : 'outlined'} onClick={() => setShow('Inspected')}>
                          Completed
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} md={component === 'map' ? 12 : 4} mt={2} justifyContent={'flex-end'} flexGrow={1} display={'flex'} gap={1}>
                    {component === 'map' && <MapSearchControl />}
                    <ToggleButtonGroup size='small' value={component} exclusive onChange={handleToggle} aria-label='text alignment'>
                      <ToggleButton value='table' aria-label='left aligned'>
                        <TableChartIcon />
                      </ToggleButton>
                      <ToggleButton disabled value='map' aria-label='right aligned'>
                        <MapIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                {component === 'table' && (
                  <DataGrid
                    reload={reload}
                    dataUrl={dataQueryUrl}
                    // rows={results}
                    orderBy='date desc'
                    // isLoading={loading}
                    columnDefinitions={columns}
                    disableRowSelectionOnClick={true}
                  />
                )}
                {component === 'map' && <MapControl mapType='inspection' height={'65vh'} />}
              </Box>
            )}
          </MainCard>
        </Grid>
      </Grid>

      <Drawer anchor={'right'} open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          component='div'
          sx={{
            width: '400px',
            marginTop: '80px',
          }}
        >
          <Container sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CancelIcon />
            </IconButton>
          </Container>
          <InspectionResultForm data={drawerData} editMode={false} onSubmit={handleInspectionResultSubmit} />
        </Box>
      </Drawer>
    </>
  );
}
