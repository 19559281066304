import Loader from 'components/Loader';
import { MapItem } from 'entities';
import { LatLng } from 'leaflet';
import { useContext, createContext, ReactNode, useReducer } from 'react';
import { ActionMap } from 'contexts/ActionTypes';

type MapProviderProps = {
  children: ReactNode;
};

interface MapContextState {
  searchQuery?: string;
}

interface MapContextType extends MapContextState {
  setSearchQuery(query?: string): void;
}

enum MapActionTypes {
  Search,
}

type MapPayload = {
  [MapActionTypes.Search]: string | undefined;
};

type MapActions = ActionMap<MapPayload>[keyof ActionMap<MapPayload>];

const mapReducer = (state: MapContextState, action: MapActions): MapContextState => {
  switch (action.type) {
    case MapActionTypes.Search:
      return { ...state, searchQuery: action.payload };
    default:
      return { ...state };
  }
};

export const MapContext = createContext<MapContextType | null>(null);

export const MapProvider = ({ children }: MapProviderProps) => {
  const [state, dispatch] = useReducer(mapReducer, {});

  const setSearchQuery = (query?: string) => dispatch({ type: MapActionTypes.Search, payload: query });

  if (!state) {
    return <Loader />;
  }

  return <MapContext.Provider value={{ ...state, setSearchQuery }}>{children}</MapContext.Provider>;
};
