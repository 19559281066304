import { Box, Grid, MenuItem, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import useApp from 'hooks/useApp';
import { useEffect, useState } from 'react';
import SignalsTable from 'components/collections/SignalsTable';
import EventLogTable from 'components/collections/EventLogTable';

const Dashboard = () => {
  const { client, user, building, site } = useApp();

  const [clientId, setClientId] = useState<number>();
  const [siteId, setSiteId] = useState<number>();
  const [buildingId, setBuildingId] = useState<number>();

  const [show, setShow] = useState<'Active' | 'All'>('All');

  const handleShowToggle = (event: React.MouseEvent<HTMLElement>, value: 'Active' | 'All') => {
    setShow(value);
  };

  useEffect(() => {
    if (user?.contact && client?.id && site?.id && building?.id) {
      setClientId(client?.id);
      setSiteId(site?.id);
      setBuildingId(building?.id);
    }
  }, []);

  return (
    <Grid container>
      <Grid spacing={gridSpacing} container>
        <Grid item xs={12} md={12}>
          <MainCard title={'Dashboard'}>
            <Grid spacing={gridSpacing} container>
              <Grid item xs={12} md={4}>
                {clientId && user?.contact?.clients && (
                  <TextField
                    select
                    fullWidth
                    value={clientId}
                    label={'Client'}
                    onChange={(e) => setClientId(parseInt(e.target.value))}
                    // SelectProps={{
                    //   native: true,
                    // }}
                  >
                    {user?.contact?.clients?.map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {clientId && siteId && (
                  <TextField
                    select
                    fullWidth
                    value={siteId}
                    label={'Site'}
                    onChange={(e) => setSiteId(parseInt(e.target.value))}
                    // SelectProps={{
                    //   native: true,
                    // }}
                  >
                    {user?.contact?.clients
                      ?.find((c) => c.id === clientId)
                      ?.sites?.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      )) ?? <MenuItem>{'No sites'}</MenuItem>}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {buildingId && (
                  <TextField
                    select
                    fullWidth
                    value={buildingId}
                    label={'Building'}
                    onChange={(e) => setBuildingId(parseInt(e.target.value))}
                    // SelectProps={{
                    //   native: true,
                    // }}
                  >
                    {user?.contact?.clients
                      ?.find((c) => c.id === clientId)
                      ?.sites?.find((s) => s.id === siteId)
                      ?.buildings?.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      )) ?? <MenuItem>{'No buildings'}</MenuItem>}
                  </TextField>
                )}
              </Grid>
            </Grid>
          </MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} md={8}>
              <MainCard
                sx={{ mt: 2 }}
                title={'Signals'}
                secondary={
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <ToggleButtonGroup size='small' value={show} exclusive onChange={handleShowToggle}>
                      <ToggleButton sx={{ width: 80 }} value='Active'>
                        Active
                      </ToggleButton>
                      <ToggleButton sx={{ width: 80 }} value='All'>
                        All
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                }
              >
                <SignalsTable showActions={true} filter={false} activeOnly={show === 'Active'} />
              </MainCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <MainCard sx={{ mt: 2 }} title={'Event Logs'}>
                <EventLogTable />
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
