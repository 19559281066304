import React from 'react';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { IntervalFieldAttributes } from '.';

export const IntervalField: React.FC<IntervalFieldAttributes> = ({ label, name, select, type, control }) => {
  const { register } = useFormContext();

  return (
    <section>
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          margin: '5px',
          width: '100%',
        }}
      >
        <Controller
          name={name}
          control={control}
          //rules={{ required: true }}
          render={({ field }) => <TextField {...field} {...register(name)} style={{ width: '60%' }} type={type} label={label} variant='outlined' />}
        />
        <Controller
          name={select}
          control={control}
          //rules={{ required: true }}
          render={({ field }) => (
            <Select {...field} {...register(select)} style={{ width: '40%' }}>
              <MenuItem value={0}>Week(s)</MenuItem>
              <MenuItem value={1}>Month(s)</MenuItem>
              <MenuItem value={2}>Year(s)</MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </section>
  );
};
