import { Dialog, DialogContent, DialogActions, Button, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';

const createPromise = () => {
  let resolver;
  return [
    new Promise<boolean>((resolve, reject) => {
      resolver = resolve;
    }),
    resolver,
  ];
};

const useConfirm = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState({ resolver: null });
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [label, setLabel] = useState('');

  const getConfirmation = async (text: string) => {
    setLabel(text);
    setOpen(true);
    const [promise, resolve] = await createPromise();
    //@ts-ignore
    setResolver({ resolve });
    return promise;
  };

  const onClick = async (status: boolean) => {
    setOpen(false);
    //@ts-ignore
    resolver.resolve(status);
  };

  const ConfirmDialog = () => (
    <Dialog fullScreen={fullScreen} open={open}>
      <DialogContent>{label}</DialogContent>
      <DialogActions>
        <Button onClick={() => onClick(false)}> Cancel </Button>
        <Button onClick={() => onClick(true)}> OK </Button>
      </DialogActions>
    </Dialog>
  );

  return { getConfirmation, ConfirmDialog };
};

export default useConfirm;
