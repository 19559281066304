import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Building, ContactGroup, NotificationGroup } from 'entities';
import useSignals from 'hooks/useSignals';
import { buildings, groups } from '../../assets/MockData';

export default function PulseInfo() {
  const { states } = useSignals();
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setState({
    //   ...state,
    //   [event.target.name]: event.target.checked,
    // });
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
        <FormLabel component='legend'>Select Signal(s)</FormLabel>
        <FormGroup>
          {states?.map((s) => (
            <FormControlLabel key={s.id} control={<Checkbox onChange={handleChange} name={s.name} />} label={`${s.name} on ${new Date(s.date)} [${s.signalActivated.address}]  `} />
          ))}
        </FormGroup>
        {/* <FormHelperText>Be careful</FormHelperText> */}
      </FormControl>
      <FormControl sx={{ m: 3 }} component='fieldset' variant='standard'>
        <FormLabel component='legend'>Select Building</FormLabel>
        <FormGroup>
          {buildings.map((b) => (
            <FormControlLabel key={b.id} control={<Checkbox onChange={handleChange} name={b.name} />} label={b.name} />
          ))}
        </FormGroup>
        {/* <FormHelperText>Be careful</FormHelperText> */}
      </FormControl>
      <FormControl required component='fieldset' sx={{ m: 3 }} variant='standard'>
        <FormLabel component='legend'>Select Contact Group</FormLabel>
        <FormGroup>
          {groups.map((g) => (
            <FormControlLabel key={g.id} control={<Checkbox onChange={handleChange} name={g.name} />} label={g.name} />
          ))}
        </FormGroup>
        {/* <FormHelperText>You can display an error</FormHelperText> */}
      </FormControl>
    </Box>
  );
}
