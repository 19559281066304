import { Typography, Button, Grid, Box } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import logger from '../utils/logger';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { FieldAttributes, Field, FieldType } from '../components/fields';
import { Device } from 'entities';
import useAxios from 'hooks/useAxios';
import MainCard from 'components/cards/MainCard';

const InitialState: Device = {
  id: 0,
  name: '',
  address: '',
  description: '',
  type: '',
  typeCode: '',
  hardwareType: '',
  serialNumber: '',
  category: '',
  notes: '',
  excluded: false,
  locationID: 0,
  groupID: 0,
  parentDeviceId: 0,
};

type DeviceViewProps = {
  disabled?: boolean;
};

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export default function DeviceView({ disabled }: DeviceViewProps) {
  const { get } = useAxios();
  const navigate = useNavigate();
  const params = useLoaderData() as string;

  const [id, setId] = useState<string | null>();
  const [device, setDevice] = useState<Device>();

  const methods = useForm<Device>({
    defaultValues: useMemo(() => {
      return device ?? InitialState;
    }, [device]),
  });

  const onSubmit: SubmitHandler<Device> = (data) => {
    logger.log(data);
    navigate('/device');
  };

  const deviceSchema: FieldAttributes[] = [
    {
      label: 'Category',
      name: 'category',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/categories',
      control: methods.control,
    },
    {
      label: 'System',
      name: 'systemInfoID',
      type: FieldType.LOOKUP,
      dataUrl: '/api/systems',
      control: methods.control,
    },
    {
      label: 'Location',
      name: 'locationID',
      type: FieldType.LOOKUP,
      dataUrl: '/api/locations',
      control: methods.control,
    },
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Address',
      name: 'address',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Barcode',
      name: 'barcode',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Description',
      name: 'description',
      type: FieldType.TEXT,
      control: methods.control,
      span: 12,
    },
    {
      label: 'Type',
      name: 'type',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/deviceTypes',
      control: methods.control,
    },
    {
      label: 'Type Code',
      name: 'typeCode',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/deviceTypeCodes',
      control: methods.control,
    },
    {
      label: 'Hardware Type',
      name: 'hardwareType',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/hardwareTypes',
      control: methods.control,
    },
    {
      label: 'Serial number',
      name: 'serialNumber',
      type: FieldType.TEXT,
      control: methods.control,
    },

    //{ label: "Exclude", name: "exclude", type: FieldType.TEXT, control: methods.control},
    {
      label: 'Parent Device',
      name: 'parentDeviceID',
      type: FieldType.LOOKUP,
      dataUrl: '/api/devices',
      control: methods.control,
    },
    {
      label: 'Notes',
      name: 'notes',
      type: FieldType.TEXT,
      control: methods.control,
      span: 12,
    },
  ];

  useEffect(() => {
    logger.log(device);
    methods.reset(device);
  }, [device]);

  useEffect(() => {
    if (id) {
      get<Device>(`/api/device/${id}`).then(setDevice);
      //   axiosInstance
      //     .get(`/api/device/${id}`)
      //     .then((r) => setDevice(r.data))
      //     .catch((e) => logger.logError('FAILED getDevice: ', e));
    }
  }, [id]);

  useEffect(() => {
    methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(params);
  }, [params]);

  return (
    <Grid item xs={12}>
      <MainCard title={disabled ? device?.name : device ? `Edit device` : 'New device'}>
        <FormProvider {...methods}>
          <form className='form' onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {deviceSchema.map((field, key) => (
                <Grid key={key} item xs={12} sm={field.fullWidth ? 12 : field.span ?? 6} md={field.fullWidth ? 12 : field.span ?? 6}>
                  <Field disabled={disabled} {...field} />
                </Grid>
              ))}
            </Grid>
            {disabled && (
              <Box component='div' sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2, mt: 2 }}>
                <Button type='button' variant='contained' onClick={() => navigate(-1)}>
                  Go back
                </Button>
              </Box>
            )}
            {!disabled && (
              <Box component='div' sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2, mt: 2 }}>
                <Button type='button' variant='outlined' onClick={() => navigate('/device')}>
                  cancel
                </Button>
                <Button type='submit' variant='contained'>
                  Submit
                </Button>
              </Box>
            )}
          </form>
        </FormProvider>
      </MainCard>
    </Grid>
  );
}
