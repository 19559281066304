import InspectionConnector from 'connectors/InspectionConnector';
import { Inspection, Device, InspectionResult, SignalState } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import useApp from './useApp';

export type InspectionResultType = 'All' | 'Signaled' | 'Visual Inspected' | 'Inspected' | 'Uninspected';

const useSignals = () => {
  // const { get } = useAxios();
  const { building } = useApp();

  const [states, setSignalStates] = useState<SignalState[]>(building?.signalStates ?? []);

  // useEffect(() => {
  //   events((signalState: SignalState) => {
  //     console.log('useSignal.states', states);
  //     console.log('events.SignalState', signalState);
  //     setSignalStates([...states.filter((s) => s.id !== signalState.id), signalState]);
  //   });
  // }, [events]);

  useEffect(() => {
    const states = building?.signalStates?.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)) ?? [];
    setSignalStates(true ? states.filter((s) => s.isActive) : states);
  }, [building?.signalStates]);

  return { states };
};

export default useSignals;
