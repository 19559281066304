import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid';
import MainCard from 'components/cards/MainCard';
import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ClientTitle } from 'components/ClientTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TableChartIcon from '@mui/icons-material/TableChart';
import CollectionsIcon from '@mui/icons-material/Collections';
import MapIcon from '@mui/icons-material/Map';
import PhotosGallery from 'components/collections/PhotosGallery';
import PhotosTable from 'components/collections/PhotosTable';
import { useNavigate } from 'react-router-dom';

const Photos = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [component, setComponent] = useState<string | null>('gallery');

  const handleToggle = (event: React.MouseEvent<HTMLElement>, component: string | null) => {
    if (component) setComponent(component);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={<ClientTitle prefix={'Photos for'} />}
          secondary={
            <Button variant='contained' onClick={() => navigate('/photo')} startIcon={<CloudUploadIcon />}>
              {'Upload'}
            </Button>
          }
        >
          <Box>
            <Box display={'flex'} justifyContent={'right'} mb={5}>
              <ToggleButtonGroup size='small' value={component} exclusive onChange={handleToggle} aria-label='text alignment'>
                <ToggleButton value='table' aria-label='left aligned'>
                  <TableChartIcon />
                </ToggleButton>
                <ToggleButton value='gallery' aria-label='centered'>
                  <CollectionsIcon />
                </ToggleButton>
                <ToggleButton disabled value='map' aria-label='right aligned'>
                  <MapIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {component === 'table' && <PhotosTable />}
            {component === 'gallery' && <PhotosGallery />}
            {component === 'map' && <>MAP</>}
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Photos;
