import * as signalR from '@microsoft/signalr';
import { Inspection } from '../entities';
import logger from 'utils/logger';

const URL = `${process.env.REACT_APP_API_BASEURL}/hub`;

class InspectionConnector {
  private connection: signalR.HubConnection;
  public inspectionEvents: (onInspectionRaised: (inspection: Inspection) => void) => void;
  static instance: InspectionConnector;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build();
    this.connection.start().catch((err) => logger.logError('FAILED to start InspectionConnector:', err));
    this.inspectionEvents = (onInspectionRaised) => {
      this.connection.on('updateInspection', (inspection: Inspection) => {
        onInspectionRaised(inspection);
      });
    };
  }
  // not needed, send from function app updates (Watcher Service)
  // public notify = (signal: Signal) => {
  //     this.connection.send("newSignal", signal).then(x => logger.log("Notified NewSignal:", signal))
  // }
  public static getInstance(): InspectionConnector {
    if (!InspectionConnector.instance) InspectionConnector.instance = new InspectionConnector();
    return InspectionConnector.instance;
  }
}

export default InspectionConnector.getInstance;
