// project imports
import { AppUser } from 'entities';
import { jwtDecode } from 'jwt-decode';
import { KeyedObject } from 'types';

export const verifyToken: (st: string) => boolean = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(accessToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

export const setSession = (accessToken?: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export interface AuthResponse {
  userId: string;
  authorizationToken: string;
  refreshToken: string;
  // user: AppUser;
}

export interface AuthContext {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: AppUser | null | undefined;
}
