import { useEffect, useState } from 'react';
import SignalsTable from 'components/collections/SignalsTable';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/ClientTitle';
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import useApp from 'hooks/useApp';
import { useLoaderData } from 'react-router-dom';

export const loader = ({ params }: any) => params?.address ?? '';

const Alarms = () => {
  const params = useLoaderData() as string;
  const [isLoading, setLoading] = useState(true);
  const { showOnMap, markerType, showMarkerType } = useApp();

  const [value, setValue] = useState('');

  const [component, setComponent] = useState<string | null>('map');
  const [show, setShow] = useState<'Active' | 'All'>(markerType === 'Alarm' ? 'Active' : 'All');

  const handleShowToggle = (event: React.MouseEvent<HTMLElement>, value: 'Active' | 'All') => {
    setShow(value);
  };

  const handleTypeToggle = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    if (value) setComponent(value);
  };

  useEffect(() => {
    if (show === 'Active') showMarkerType('Alarm');
    else showMarkerType('All');
  }, [show]);

  const search = () => {
    showOnMap(value);
  };

  useEffect(() => {
    if (params) showOnMap(params);
  }, [params]);

  useEffect(() => {
    setLoading(false);

    return () => {
      showOnMap('');
    };
  }, []);

  return (
    <MainCard title={<ClientTitle prefix={'Alarms & Alerts for'} />}>
      <Box display={'flex'} justifyContent={'space-between'} mb={5}>
        <ToggleButtonGroup size='small' value={show} exclusive onChange={handleShowToggle}>
          <ToggleButton sx={{ width: 80 }} value='Active'>
            Active
          </ToggleButton>
          <ToggleButton sx={{ width: 80 }} value='All'>
            All
          </ToggleButton>
        </ToggleButtonGroup>
        {/* <DateTimeRangePicker disabled /> */}
      </Box>
      <SignalsTable filter={true} activeOnly={show === 'Active'} />
    </MainCard>
  );
};

export default Alarms;
