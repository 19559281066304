import { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';

type EventLogTableProps = {
  pageSizeOptions?: number[];
};

export default function EventLogTable({ pageSizeOptions }: EventLogTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Incident',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid hideHeader={false} orderBy={'date desc'} pageSizeOptions={pageSizeOptions} columnDefinitions={columns} />;
}
