import { useEffect, useState, ReactNode } from 'react';
import { gridSpacing } from 'config';
import MapControl from 'components/maps/MapControl';
import MainCard from 'components/cards/MainCard';
import SignalStatesList from 'components/collections/SignalStatesList';
import useApp from 'hooks/useApp';
import { OutlinedInput, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { IconRecycle, IconSearch, IconX } from '@tabler/icons-react';
import { ClientTitle } from 'components/ClientTitle';
import DevicesList from 'components/collections/DevicesList';
import { DragDropContext } from '@hello-pangea/dnd';
import { StrictModeDroppable } from 'components/dragDrop/StrictModeDroppable';
import { MapItem } from 'entities';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import logger from '../utils/logger';
import { IconButton, Stack, Switch, Typography, Link, Button, Grid, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '../components/dataGrid/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import { MapSearchControl } from 'components/maps/MapSearchControl';
import useMaps from 'hooks/useMaps';
import { DeleteForever, Sync } from '@mui/icons-material';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

function RenderActionCell(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>): ReactNode {
  const { patch } = useAxios();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(params.row.excluded ?? false);

  const handleEdit = (id: number) => {
    logger.log('handleEdit(%s)', id);
    navigate(`/device/${id}`);
  };

  const handleDeviceVisibility = (id: number, exclude: boolean) => {
    logger.log('handleDeviceVisibility(%s, %s)', id, exclude);
    patch(`/api/device/visibility?id=${id}&exclude=${exclude}`).then(() => setChecked(exclude));
  };

  //logger.log(params);
  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton size='small' aria-label='edit' color='inherit' onClick={() => handleEdit(params.row.id)}>
        <EditIcon />
      </IconButton>
      <Switch checked={checked} onChange={(e) => handleDeviceVisibility(params.row.id, e.target.checked)} />
      <Typography>{checked ? 'Hidden' : 'Visible'}</Typography>
    </Stack>
  );
}

const DevicesView = () => {
  const { post } = useAxios();
  const navigate = useNavigate();
  const { success } = useNotification();
  const { mapItem, showOnMap, refreshMaps } = useApp();

  const [value, setValue] = useState('');
  const [dragId, setDragId] = useState<number>();

  const [isLoading, setLoading] = useState(true);

  const [type, setType] = useState<'Unmapped' | 'Uninspected'>('Unmapped');

  const [component, setComponent] = useState<string | null>('map');

  const columns: GridColDef[] = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'edit',
      headerName: '',
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      filterable: false,
      renderCell(params) {
        return (
          <Link component={RouterLink} to={`/device/${params.row.id}`}>
            {params.value?.toString().replaceAll('_', ' ')}
          </Link>
        );
      },
    },
    { field: 'type', headerName: 'Type', width: 300 },
    //{ field: 'description', headerName: 'Description', filterable: false, width: 400 },
    { field: 'address', headerName: 'Address', filterable: false, width: 300 },
    {
      field: 'group',
      headerName: 'Group',
      filterable: false,
      width: 300,
      renderCell(params) {
        return params.row.group.name;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      filterable: false,
      width: 300,
      renderCell(params) {
        return (
          <Link component={RouterLink} to={`/dashboard/${params.row.address}`}>
            {params.row.location?.name}
          </Link>
        );
      },
    },
  ];

  const handleTypeToggle = (event: React.MouseEvent<HTMLElement>, value: string | null) => {
    if (value) setComponent(value);
  };

  const handleDragEnd = () => {
    if (mapItem) {
      post<MapItem>('/api/mapitem', { ...mapItem, deviceId: dragId }).then(() => {
        refreshMaps();
        success(`Sucessfully added map item`);
      });
    }
  };
  // const showInfo = (id: string) => {};

  const search = () => {
    showOnMap(value);
  };

  useEffect(() => {
    setLoading(false);

    return () => {
      showOnMap('');
    };
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={<ClientTitle prefix={'Devices for'} />}
          secondary={
            <Box display={'flex'} gap={2}>
              {component === 'table' && (
                <Button variant='contained' onClick={() => navigate('/device')} size='small'>
                  Add
                </Button>
              )}
              {component === 'map' && <MapSearchControl />}
              <ToggleButtonGroup size='small' value={component} exclusive onChange={handleTypeToggle}>
                <ToggleButton value='table'>
                  <TableChartIcon />
                </ToggleButton>
                <ToggleButton value='map'>
                  <MapIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }
        >
          {component === 'table' && <DataGrid dataUrl={'/api/device/query'} columnDefinitions={columns} hideHeader={false} />}
          {component === 'map' && (
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={(e) => setDragId(parseInt(e.draggableId))}>
              <StrictModeDroppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <Box component='div' sx={{ width: '100%', height: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} md={8}>
                        <Box component='div' ref={provided.innerRef} {...provided.droppableProps}>
                          <MapControl mapType='general' height={'70vh'} showMenu={true} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DevicesList type={type} />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          )}
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default DevicesView;
