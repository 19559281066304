import { MapItem } from 'entities/MapItem';
import { DivIcon, LatLng, PointExpression } from 'leaflet';
import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import { Divider } from '@mui/material';
import { MoveItem } from './contextMenu/menuItems/MoveItem';
import { DeleteItem } from './contextMenu/menuItems/DeleteItem';
import { EditItem } from './contextMenu/menuItems/EditItem';
import ContextMenu from './contextMenu';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import useApp from 'hooks/useApp';
import { random } from 'lodash';
import { AppMode } from 'entities/enums/AppMode';
import { MapType } from './MapControl';
import { InfoOutlined } from '@mui/icons-material';

type MapMarkerProps = {
  item: MapItem;
  size: number;
  zoom: number;
  showMenu?: boolean;
  mapType: MapType;
};

export type MarkerType = 'All' | 'Alarm' | 'Active' | 'Inspected' | 'Uninspected';

export function MapMarker({ item, size, zoom, showMenu, mapType }: MapMarkerProps) {
  const lmap = useMap();
  const { put, del } = useAxios();
  const { warning } = useNotification();
  const { mapItemId, building, refreshMaps, markerType, showOnMap, selectedMapItem } = useApp();

  const markerRef = useRef(null);
  const [icon, setIcon] = useState<DivIcon>();

  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState({ lat: item.x, lng: item.y });
  const [offSetPopup, setOffSetPopup] = useState<PointExpression>([-5, 0]);
  const [mouseEvent, setMouseEvent] = useState<{ event: React.MouseEvent; position: LatLng }>();

  const isAlarm = () =>
    building?.signalStates?.find((s) => s.signalActivated?.deviceID === item.deviceId /*&& (s.signalActivated?.signalType?.name.indexOf('ALARM') ?? -1 >= 0)*/)?.isActive ?? false;
  const isTrouble = () =>
    building?.signalStates?.find((s) => s.signalActivated?.deviceID === item.deviceId && (s.signalActivated?.signalType?.name.indexOf('TRBL') ?? -1 >= 0))?.isActive ?? false;

  const isActive = () => (building?.signalStates?.find((s) => s.signalActivated?.deviceID === item.deviceId) ? true : false);
  const isInspected = () => random(0, 1) === 1;
  const isUninspected = () => random(0, 1) === 1;

  const shouldShow = () => {
    if (markerType === 'Alarm') return isAlarm();
    if (markerType === 'Active') return isActive();
    if (markerType === 'Inspected') return isInspected();
    if (markerType === 'Uninspected') return isUninspected();

    return true; // markerType === 'All';
  };

  const getMarkerStyle = (): string => {
    if (mapType !== 'general') {
      const state = ' pulse'; //appState?.mode === AppMode.Normal ? ' pulse' : '';
      if (isAlarm()) return `alarm ${state}`;
      if (isTrouble()) return `trouble ${state}`;

      // TODO implement
      // if (isInspected()) return 'inspected';
      // if (isUninspected()) return 'uninspected';

      return zoom >= 5 ? 'marker' : '';
    }

    return item.deviceId ? 'marker' : 'label';
  };

  const handleEditItem = () => {};

  const handleDragItem = () => {
    toggleDraggable();
    setMouseEvent(undefined);
  };

  const handleDeleteItem = () => {
    setMouseEvent(undefined);
    const itemName = item.device?.address;
    del(`/api/mapItem/${item.id}`).then(() => {
      refreshMaps();
      warning(`Sucessfully deleted map item`);
    });
  };

  useEffect(() => {
    if (mapItemId === item.id) {
      const marker = markerRef.current;
      if (marker !== null) {
        if (!selectedMapItem) {
          lmap.flyTo([item.x, item.y], 7);
          lmap.invalidateSize(true);
        }
        // @ts-ignore
        marker.openPopup();
      }
    }
  }, [markerRef.current, mapItemId]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          // @ts-ignore
          const newCoordinates = marker.getLatLng();
          setPosition(newCoordinates);
          setDraggable(false);
          put<MapItem>(`/api/mapItem/${item.id}`, { ...item, x: newCoordinates.lat, y: newCoordinates.lng }).then(refreshMaps);
        }
      },
      popupclose(e: any) {
        if (mapItemId) showOnMap(undefined);
      },
      contextmenu(e: any) {
        setMouseEvent({ event: e.originalEvent, position: e.position });
      },
    }),
    []
  );

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  useEffect(() => {
    const iconName = item.device?.deviceType?.iconName;
    //get<string>(`/api/map/icon`, { params: { name: iconName } }).then((svg) => {
    const iconString = renderToString(
      iconName ? (
        <img
          className={getMarkerStyle()}
          style={{ width: `${size}px`, height: `${size}px`, marginTop: `-${size / 2}px`, marginLeft: `-${size / 2}px` }}
          src={`/icons/devices/${iconName}`}
        />
      ) : (
        <InfoOutlined color={'warning'} style={{ width: `${size}px`, height: `${size}px`, marginTop: `-${size / 2}px`, marginLeft: `-${size / 2}px` }} />
      )
    );
    setIcon(new DivIcon({ html: iconString }));
  }, [item, size, building?.signalStates]);

  return (
    icon &&
    shouldShow() && (
      <Marker draggable={draggable} eventHandlers={eventHandlers} position={position} ref={markerRef} icon={icon}>
        <Popup offset={offSetPopup}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'space-between' }}>
            <h2>{item.device?.name?.replaceAll('_', ' ') ?? item.name?.replaceAll('_', ' ')}</h2>
          </div>
          {item.device?.address}
          <br />
          {item.device?.description}
        </Popup>
        {showMenu && (
          <ContextMenu mouseEvent={mouseEvent}>
            <EditItem
              onClick={() => {
                // console.log('edit item:', item);
                setMouseEvent(undefined);
              }}
            />
            <MoveItem onClick={handleDragItem} />
            <Divider />
            <DeleteItem onClick={handleDeleteItem} />
          </ContextMenu>
        )}
      </Marker>
    )
  );
}
