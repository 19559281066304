import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/CameraAlt';

const PhotoSection = () => {
  return (
    <Box sx={{ ml: 2 }}>
      <Button disabled variant='contained' sx={{ height: 45 }}>
        <CameraIcon />
      </Button>
    </Box>
  );
};

export default PhotoSection;
