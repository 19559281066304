import { useEffect, useState } from 'react';
import exifr from 'exifr';

// material-ui
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// project import
import getDropzoneData from 'utils/getDropzoneData';
import useConfig from 'hooks/useConfig';

// assets
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// type
import { DropzopType, FilePreviewProps } from 'types/dropzone';
import { Photo } from 'entities';

// ==============================|| MULTI UPLOAD - PREVIEW ||============================== //

export default function FilesPreview({ files, onRemove, type }: FilePreviewProps) {
  const { borderRadius } = useConfig();

  const [photos, setPhotos] = useState<Photo[]>([]);

  const hasFile = files.length > 0;
  const layoutType = type;

  const getPhotoData = async (file: string | File): Promise<Photo> => {
    const properties = await exifr.parse(file);
    console.log(properties);
    // const { latitude, longitude } = await exifr.gps(file);
    alert(JSON.stringify(properties));
    return {
      src: '',
      content: JSON.stringify(properties),
      width: 0,
      height: 0,
      name: '',
      id: 0,
      // lat: latitude,
      // lon: longitude,
    };
  };

  useEffect(() => {
    console.log('PHOTOS', photos);
  }, [photos]);

  useEffect(() => {
    const promises = files.map(getPhotoData);
    Promise.all(promises).then(setPhotos);
  }, [files]);

  return (
    <List
      disablePadding
      sx={{
        ...(hasFile && type !== DropzopType.standard && { my: 3 }),
        ...(type === DropzopType.standard && { width: 'calc(100% - 84px)' }),
      }}
    >
      {files.map((file, index) => {
        const { key, name, size, preview, type } = getDropzoneData(file, index);

        return (
          <ListItem
            key={key}
            sx={{
              my: 1,
              px: 2,
              py: 0.75,
              borderRadius: `${borderRadius}px`,
              border: 'solid 1px',
              borderColor: 'secondary.light',
            }}
          >
            {(type?.includes('image') && <img alt='preview' src={preview} style={{ width: 50, height: 50 }} />) ?? (
              <InsertDriveFileOutlinedIcon sx={{ color: 'secondary.main', width: 30, height: 30, fontSize: '1.15rem', mr: 0.5 }} />
            )}
            {JSON.stringify(file)}
            {/* <ListItemText
              primary={typeof file === 'string' ? file : name}
              secondary={typeof file === 'string' ? '' : size}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            /> */}

            {onRemove && (
              <IconButton edge='end' size='small' onClick={() => onRemove(file)} color='error'>
                <HighlightOffIcon style={{ fontSize: '1.15rem' }} />
              </IconButton>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
