import { useState, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import { AddItem } from './contextMenu/menuItems/AddItem';
import ContextMenu, { MouseEventProps } from './contextMenu';

export default function MapMenu() {
  const lmap = useMap();
  const [mouseEvent, setMouseEvent] = useState<MouseEventProps>();

  //@ts-ignore
  const handlers = useMemo(() => ({ contextmenu: (e: React.MouseEvent) => setMouseEvent({ event: e.originalEvent, position: e.latlng }) }), []);
  // @ts-ignore TODO fix if needed
  useEventHandlers({ instance: lmap }, handlers);

  return (
    <ContextMenu mouseEvent={mouseEvent}>
      <AddItem position={mouseEvent?.position} onClick={() => setMouseEvent(undefined)} />
    </ContextMenu>
  );
}
