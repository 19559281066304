import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnimateButton from 'components/extended/AnimateButton';
import { AuthWrapper } from './AuthWrapper';

// ==============================|| CHECK MAIL ||============================== //

const CheckMail = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthWrapper>
      <Grid item xs={12}>
        <Grid container alignItems='center' justifyContent='center' textAlign='center' spacing={2}>
          <Grid item xs={12}>
            <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
              Hi, Check your mail
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' fontSize='16px' textAlign={matchDownSM ? 'center' : 'inherit'}>
              We have sent a password recover instructions to your email.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <AnimateButton>
          <Button disableElevation fullWidth size='large' type='submit' variant='contained' color='secondary'>
            LOGIN
          </Button>
        </AnimateButton>
      </Grid> */}
    </AuthWrapper>
  );
};

export default CheckMail;
