import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid';
import MainCard from 'components/cards/MainCard';
import EventLogTable from 'components/collections/EventLogTable';
import { Button } from '@mui/material';

const EventLogs = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={'Event Logs'}
          secondary={
            <Button disabled variant='contained'>
              {'New Entry'}
            </Button>
          }
        >
          <EventLogTable />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default EventLogs;
