import { Box, Grid } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import useApp from 'hooks/useApp';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PulsePanel from 'components/pulse/PulsePanel';
import React from 'react';
import PulseList from 'components/pulse/PulseList';

const Pulse = () => {
  const { client } = useApp();
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={8}>
          <MainCard title={'Pulse'}>
            <PulsePanel />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard title={'Pulse Messages'}>
            <PulseList />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pulse;
