import * as signalR from '@microsoft/signalr';
import { SignalState } from '../entities';
import logger from 'utils/logger';

const URL = `${process.env.REACT_APP_API_BASEURL}/hub`;

class SignalStateConnector {
  private connection: signalR.HubConnection;
  public signalStateEvents: (onSignalStateRaised: (signalState: SignalState) => void) => void;
  static instance: SignalStateConnector;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build();
    this.connection.start().catch((err) => logger.logError('FAILED to start SignalStateConnector:', err));
    this.signalStateEvents = (onSignalStateRaised) => {
      this.connection.on('signalStateUpdate', (signalState: SignalState) => {
        onSignalStateRaised(signalState);
      });
    };
  }
  // not needed, send from function app updates (Watcher Service)
  // public notify = (signal: Signal) => {
  //     this.connection.send("newSignal", signal).then(x => logger.log("Notified NewSignal:", signal))
  // }
  public static getInstance(): SignalStateConnector {
    if (!SignalStateConnector.instance) SignalStateConnector.instance = new SignalStateConnector();
    return SignalStateConnector.instance;
  }
}

export default SignalStateConnector.getInstance;
