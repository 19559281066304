import useApp from 'hooks/useApp';

type SiteNameProps = {
  prefix?: string;
  suffix?: string;
  showClient?: boolean;
};

export const ClientTitle = ({ prefix, suffix, showClient }: SiteNameProps) => {
  const { client, site, building } = useApp();

  if (showClient && !prefix && !suffix) return <>{client?.name}</>;

  if (suffix) return <>{`${suffix} ${building?.name}`}</>;

  return <>{`${prefix ?? site?.code + ' - '} ${(showClient ? client : building)?.name}`.trim()}</>;
};
