import * as React from 'react';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Notification } from 'entities';
import { Box } from '@mui/material';
import { leadingZeros } from 'utils/extensions';

function RenderPulse(notification: Notification) {
  return (
    <Box key={notification.id}>
      <ListItem alignItems='flex-start'>
        <ListItemAvatar>
          <Avatar alt='Alarm' sx={{ backgroundColor: 'red' }} src='icons/devices/Other-AlarmLight.svg' />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                {`#${leadingZeros(notification.id, 4)}`}
              </Typography>
              {notification.name}
            </React.Fragment>
          }
          secondary={new Date().toLocaleDateString() + ' - ' + new Date().toLocaleTimeString()}
        />
      </ListItem>
      <Divider variant='inset' component='li' />
    </Box>
  );
}

export default function PulseList() {
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      buildingID: 1,
      id: 1,
      name: 'Alarm at stairway A',
    },
  ]);

  return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>{notifications?.map(RenderPulse)}</List>;
}
