import { OpenWith } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemProps } from '..';

export const MoveItem = ({ onClick }: MenuItemProps) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <OpenWith fontSize='small' />
      </ListItemIcon>
      <ListItemText>Move marker</ListItemText>
    </MenuItem>
  );
};
