import * as signalR from '@microsoft/signalr';
import { AppState } from 'entities/AppState';
import logger from 'utils/logger';

const URL = `${process.env.REACT_APP_API_BASEURL}/hub`;

class AppStateConnector {
  private connection: signalR.HubConnection;
  public appStateEvents: (onAppStateRaised: (appState: AppState) => void) => void;
  static instance: AppStateConnector;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build();
    this.connection.start().catch((err) => logger.logError('FAILED to start AppStateConnector:', err));
    this.appStateEvents = (onAppStateRaised) => {
      this.connection.on('stateUpdate', (appState: AppState) => {
        logger.log('AppStateConnector', appState);
        onAppStateRaised(appState);
      });
    };
  }
  // not needed, send from api updates
  // public notify = (state: AppState) => {
  //     this.connection.send("stateUpdate", state).then(x => logger.log("Notified StateUpdate:", state))
  // }
  public static getInstance(): AppStateConnector {
    if (!AppStateConnector.instance) AppStateConnector.instance = new AppStateConnector();
    return AppStateConnector.instance;
  }
}

export default AppStateConnector.getInstance;
