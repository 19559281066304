export default class logger {
  static verbose =
    window.location.hostname === 'localhost' || window.location.hostname.toLowerCase().indexOf('test') !== -1 || window.location.search.toLowerCase().indexOf('verbose') !== -1;

  public static log(message?: any, ...optionalParams: any[]): void {
    if (this.verbose) console.log(message, ...optionalParams);
  }

  public static logError(message?: any, ...optionalParams: any[]): void {
    /*if (this.verbose)*/ console.error(message, ...optionalParams);
  }
}
