import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid';
import MainCard from 'components/cards/MainCard';
import { Button } from '@mui/material';
import { ClientTitle } from 'components/ClientTitle';
import ShiftsTable from 'components/shifts/ShiftsTable';

const Shifts = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={<ClientTitle prefix={'My shifts for'} />}
          secondary={
            <Button disabled variant='contained'>
              {'New Shift'}
            </Button>
          }
        >
          <ShiftsTable />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Shifts;
