import { createBrowserRouter } from 'react-router-dom';
import DashboardLayout from 'pages/dashboard';
import MainLayout from 'pages/main';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NavMotion from 'components/NavMotion';
import GuestGuard from 'utils/route-guard/GuestGuard';
import Device, { loader as DeviceLoader } from './views/Device';
import Inspection, { loader as InspectionLoader } from './views/Inspection';
import Photo, { loader as PhotoLoader } from './views/Photo';
import Maps, { loader as mapLoader } from './views/Maps';
import Alarms, { loader as alarmsLoader } from './views/Alarms';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
import { ClientTitle } from 'components/ClientTitle';
import {
  Dashboard as DashboardIcon,
  Sensors,
  AssuredWorkload,
  NotificationImportant,
  ManageAccounts,
  Timelapse,
  CalendarMonth,
  List,
  Map,
  Devices as DeviceIcon,
  ImportExport,
  Add,
  AccessAlarm,
  DeviceHub,
  Assessment,
  MergeType,
  Settings as SettingsIcon,
  Tune,
  Person,
  CameraAlt,
  Collections,
  EmergencyShare,
  AssignmentLate,
  GroupAdd,
  PersonAdd,
  SettingsInputComponent,
  TypeSpecimen,
} from '@mui/icons-material';
import Login from 'pages/authentication/Login';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import CheckMail from 'pages/authentication/CheckMail';
import ResetPassword from 'pages/authentication/ResetPassword';
import Error404 from 'pages/Error404';
import Error500 from 'pages/Error500';
import UnderConstruction from 'pages/UnderConstruction';
import Landing from 'pages/landing';
import Dashboard from 'views/Dashboard';
import Pulse from 'views/Pulse';
import Settings from 'views/Settings';
import Inspections from 'views/Inspections';
import Shifts from 'views/Shifts';
import Photos from 'views/Photos';
import Contacts from 'views/Contacts';
import Notifications from 'views/Notifications';
import Devices from 'views/Devices';
import InspectionTypes from 'views/InspectionTypes';
import InspectionType from 'views/InspectionType';
import SignalTypes from 'views/SignalTypes';
import EventLogs from 'views/EventLogs';

// constant
export const icons = {
  Profile: Person,
  Dashboard: DashboardIcon,
  Signals: Sensors,
  Inspections: AssuredWorkload,
  Notifications: NotificationImportant,
  ManageAccounts,
  Shifts: Timelapse,
  CalendarMonth,
  List,
  Map,
  Device: SettingsInputComponent,
  ImportExport,
  Add,
  AccessAlarm,
  Assessment,
  Type: TypeSpecimen,
  SettingsIcon,
  Tune,
  Camera: CameraAlt,
  Collections,
  Alarm: EmergencyShare,
  EventLogs: AssignmentLate, // Warning,
  GroupAdd,
  PersonAdd,
};

// const LandingPage = Loadable(lazy(() => import('pages/landing')));
// const Error404 = Loadable(lazy(() => import('pages/Error')));
// const Error500 = Loadable(lazy(() => import('pages/Error500')));
// const UnderConstruction = Loadable(lazy(() => import('pages/UnderConstruction')));
// const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/authentication/CheckMail')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
// const Alamrs = Loadable(lazy(() => import('views/Alarms')));
// const Dashboard = Loadable(lazy(() => import('views/Dashboard')));
// const Device = Loadable(lazy(() => import('views/Device')));
// const Devices = Loadable(lazy(() => import('views/Devices')));
// const Maps = Loadable(lazy(() => import('views/Maps')));
// const Photo = Loadable(lazy(() => import('views/Photo')));
// const Photos = Loadable(lazy(() => import('views/Photos')));
// const Settings = Loadable(lazy(() => import('views/Settings')));
// const SignalTypes = Loadable(lazy(() => import('views/SignalTypes')));
// const Inspection = Loadable(lazy(() => import('views/Inspection')));
// const Inspections = Loadable(lazy(() => import('views/Inspections')));
// const InspectionType = Loadable(lazy(() => import('views/InspectionType')));
// const InspectionTypes = Loadable(lazy(() => import('views/InspectionTypes')));
// const EventLogs = Loadable(lazy(() => import('views/Incidents')));
// const Shifts = Loadable(lazy(() => import('views/Shifts')));
// const Contacts = Loadable(lazy(() => import('views/Contacts')));
// const Pulse = Loadable(lazy(() => import('views/Pulse')));
// const Notifications = Loadable(lazy(() => import('views/Notifications')));

const AuthRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/pulse',
      element: <Pulse />,
    },
    {
      path: '/settings',
      element: <Settings />,
    },
    {
      path: '/alarms',
      element: <Alarms />,
    },
    {
      path: '/alarms',
      element: <Alarms />,
    },
    {
      path: '/maps/:address?',
      element: <Maps />,
      loader: mapLoader,
    },
    {
      path: '/inspection',
      element: <Inspections />,
    },
    {
      path: '/inspection/:id',
      element: <Inspection />,
      loader: InspectionLoader,
    },
    {
      path: '/eventlogs',
      element: <EventLogs />,
    },
    {
      path: '/eventlogs/:id',
      element: <></>,
    },
    {
      path: '/shifts',
      element: <Shifts />,
    },
    {
      path: '/photo',
      element: <Photos />,
    },
    {
      path: '/photo/:id',
      element: <Photo />,
      loader: PhotoLoader,
    },
    {
      path: '/device/:id',
      element: <Device disabled={true} />,
      loader: DeviceLoader,
    },
    {
      path: '/contact',
      element: <Contacts />,
    },
    {
      path: '/notifications',
      element: <Notifications />,
    },
    {
      path: '/device',
      element: <Devices />,
    },
    {
      path: '/device/:id',
      element: <Device />,
      loader: DeviceLoader,
    },
    {
      path: '/types/inspection',
      element: <InspectionTypes />,
    },
    {
      path: '/types/inspection/:id',
      element: <InspectionType />,
      loader: InspectionLoader,
    },
    {
      path: '/types/signal',
      element: <SignalTypes />,
    },
  ],
};

const LoginRoutes = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/forgot',
      element: <ForgotPassword />,
    },
    {
      path: '/check-mail',
      element: <CheckMail />,
    },
    {
      path: '/reset',
      element: <ResetPassword />,
    },
  ],
};

const FallbackRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/404',
      element: <Error404 />,
    },
    {
      path: '/500',
      element: <Error500 />,
    },
    {
      path: '/maintenance',
      element: <UnderConstruction />,
    },
  ],
};

export const menuItems: { items: NavItemType[] } = {
  items: [
    {
      id: 'dashboard',
      // title: <ClientTitle showClient={true} />,
      //icon: icons.Dashboard,
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: <FormattedMessage id='dashboard' />,
          type: 'item',
          url: '/dashboard',
          icon: icons.Dashboard,
        },
        {
          id: 'pulse',
          title: <FormattedMessage id='pulse' />,
          type: 'item',
          url: '/pulse',
          icon: icons.Notifications,
          disabled: true,
        },
        {
          id: 'eventLogs',
          title: <FormattedMessage id='eventLogs' />,
          type: 'item',
          url: '/eventlogs',
          icon: icons.EventLogs,
        },
        {
          id: 'reports',
          title: <FormattedMessage id='reports' />,
          type: 'item',
          url: '/reports',
          icon: icons.Assessment,
          disabled: true,
        },
      ],
    },
    {
      id: 'site',
      title: <ClientTitle />,
      icon: icons.Dashboard,
      type: 'group',
      children: [
        // {
        //   id: 'alarms',
        //   title: <FormattedMessage id='alarms' />,
        //   type: 'item',
        //   url: '/alarms',
        //   icon: icons.Alarm,
        // },
        {
          id: 'maps',
          title: <FormattedMessage id='maps' />,
          type: 'item',
          url: '/maps',
          icon: icons.Map,
        },
        {
          id: 'inspections',
          title: <FormattedMessage id='inspections' />,
          type: 'item',
          url: '/inspection',
          icon: icons.Inspections,
        },
        {
          id: 'photos',
          title: <FormattedMessage id='photos' />,
          type: 'item',
          url: '/photo',
          icon: icons.Collections,
        },
        // {
        //   id: 'reports',
        //   title: <FormattedMessage id='reports' />,
        //   type: 'item',
        //   url: '/report',
        //   icon: icons.Assessment,

        //   disabled: true,
        // },
        // {
        //   id: 'notifications',
        //   title: <FormattedMessage id='notifications' />,
        //   type: 'item',
        //   url: '/notification',
        //   icon: icons.Notifications,
        // },
      ],
    },
    {
      id: 'config',
      title: <FormattedMessage id='config' />,
      type: 'group',
      icon: icons.Tune,
      adminOnly: true,
      children: [
        {
          id: 'contacts',
          title: <FormattedMessage id='contacts' />,
          type: 'item',
          icon: icons.PersonAdd,
          url: '/contact',
          disabled: true,
        },
        {
          id: 'contactGroups',
          title: <FormattedMessage id='contactGroups' />,
          type: 'item',
          icon: icons.GroupAdd,
          url: '/contactgroups',
          disabled: true,
        },
        {
          id: 'devices',
          title: <FormattedMessage id='devices' />,
          type: 'item',
          icon: icons.Device,
          url: '/device',
        },
        {
          id: 'types',
          title: <FormattedMessage id='types' />,
          type: 'collapse',
          icon: icons.Type,
          url: '/types',
          children: [
            {
              id: 'inspectionTypes',
              title: <FormattedMessage id='inspectionTypes' />,
              type: 'item',
              url: '/types/inspection',
            },
            {
              id: 'signalTypes',
              title: <FormattedMessage id='signalTypes' />,
              type: 'item',
              url: '/types/signal',
            },
          ],
        },
        {
          id: 'notifications',
          title: <FormattedMessage id='notifications' />,
          icon: icons.Notifications,
          type: 'item',
          url: '/notifications',
        },
      ],
    },
    {
      id: 'utilities',
      title: <FormattedMessage id='utilities' />,
      icon: icons.SettingsIcon,
      type: 'group',
      children: [
        {
          id: 'calendar',
          title: <FormattedMessage id='calendar' />,
          type: 'item',
          url: '/calendar',
          icon: icons.CalendarMonth,
          disabled: true,
        },
        {
          id: 'camera',
          title: <FormattedMessage id='camera' />,
          type: 'item',
          url: '/photos',
          icon: icons.Camera,
          disabled: true,
        },
        {
          id: 'shift',
          title: <FormattedMessage id='shifts' />,
          type: 'item',
          url: '/shifts',
          icon: icons.Shifts,
        },
      ],
    },
  ],
};

export const router = createBrowserRouter([{ path: '/', element: <Landing />, errorElement: <Error404 /> }, FallbackRoutes, LoginRoutes, AuthRoutes], {
  basename: '',
});
