import useAxios from 'hooks/useAxios';
import { useState, useEffect, useContext } from 'react';
import { Map as TileMap } from '../../entities';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Radio,
  Typography,
} from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Check } from '@mui/icons-material';
import useApp from 'hooks/useApp';
import { useMap } from 'react-leaflet';
import { MAPCENTER, MAPZOOM } from './MapControl';

export default function MapSelector() {
  const lmap = useMap();
  const { building, map, setMap } = useApp();
  const { height, width } = useWindowSize();

  const [maps, setMaps] = useState<TileMap[]>();
  const [selected, setSelected] = useState<number>();
  const [expanded, setExpanded] = useState(true);

  const [showMarkers, setShowMarkers] = useState(true);
  const [showLabels, setShowLabels] = useState(true);
  const [showGrid, setShowGrid] = useState(false);

  const actionMap = new Map([
    [
      'cbShowGrid',
      (checked: boolean) => {
        setShowGrid(checked);
        setMap({ ...map!, showGrid: checked });
      },
    ],
    [
      'cbShowLabels',
      (checked: boolean) => {
        setShowLabels(checked);
        setMap({ ...map!, showLabels: checked });
      },
    ],
    [
      'cbShowMarkers',
      (checked: boolean) => {
        setShowMarkers(checked);
        setMap({ ...map!, showMarkers: checked });
      },
    ],
  ]);

  const handleChange = (id: number) => {
    setSelected(id);
    lmap.setView(MAPCENTER, MAPZOOM);
    lmap.invalidateSize(true);
    setMap(maps?.find((m) => m.id === id));
  };

  const toggleOverlay = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const action = actionMap.get(event.target.id);
    if (action) action(checked);
  };

  useEffect(() => {
    if (width) setExpanded(width > 900);
  }, [width]);

  // useEffect(() => {
  //   setMap(maps?.find((m) => m.id === selected));
  // }, [selected]);

  useEffect(() => {
    setSelected(map?.id);
    setShowGrid(map?.showGrid ?? false);
    setShowLabels(map?.showLabels ?? false);
    setShowMarkers(map?.showMarkers ?? false);
    lmap.invalidateSize();
  }, [map]);

  useEffect(() => {
    if (building?.maps) {
      setMaps(building?.maps);
    }
  }, [building?.maps]);

  return (
    <div className={'leaflet-top leaflet-right'}>
      <div className='leaflet-control leaflet-bar'>
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
            <Typography>Maps</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ height: height && height < 900 ? height / 3 : '100%' }}>
            <PerfectScrollbar>
              <FormControl>
                <MenuList dense>
                  {maps?.map((m) =>
                    selected == m.id ? (
                      <MenuItem selected={selected == m.id} key={m.id} onClick={(e) => handleChange(m.id)}>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                        {m.name.substring(12)}
                      </MenuItem>
                    ) : (
                      <MenuItem selected={selected == m.id} key={m.id} onClick={(e) => handleChange(m.id)}>
                        <ListItemText title={m.url} inset>
                          {m.name.substring(12)}
                        </ListItemText>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </FormControl>
              <Divider />
              <Box mt={3}>
                <FormGroup>
                  <FormLabel id='overlays'>Overlays</FormLabel>
                  {/* <FormControlLabel
                    control={<Checkbox disabled={!map?.hasMarkers ?? true} checked={showMarkers} size='small' id={'cbShowMarkers'} onChange={toggleOverlay} />}
                    label='Show devices'
                  /> */}
                  <FormControlLabel
                    control={<Checkbox disabled={!map?.hasLabels ?? true} checked={showLabels} size='small' id={'cbShowLabels'} onChange={toggleOverlay} />}
                    label='Show labels'
                  />
                  <FormControlLabel
                    control={<Checkbox disabled={!map?.hasGrid ?? true} checked={showGrid} size='small' id={'cbShowGrid'} onChange={toggleOverlay} />}
                    label='Show grid'
                  />
                </FormGroup>
              </Box>
            </PerfectScrollbar>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
