import { AppMode } from 'entities/enums/AppMode';
import { SignalType, ContactGroup, NotificationRelay, Building } from 'entities';

export const types: SignalType[] = [
  {
    id: 1,
    name: 'TAMPER ACTIVE',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 3,
    name: 'TAMPER RESTORED',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 4,
    name: 'LOCAL TRBL ACT',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 5,
    name: 'LOCAL MNTR RST',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 6,
    name: 'COMMON TRBL ACT',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 7,
    name: 'LOCAL TRBL RST',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 8,
    name: '-OPERATOR COMMAND-',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 9,
    name: 'COMMON TRBL RST',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 10,
    name: 'DISABLED ACTIVE',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 11,
    name: 'MAINT ALERT RST',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 12,
    name: 'MONITOR RESTORED',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 13,
    name: 'DISABLED RESTORE',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 14,
    name: 'SWITCH RESTORED',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 15,
    name: 'PULL STATION ACT',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 16,
    name: 'TROUBLE SHRT ACT',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 17,
    name: 'TROUBLE SHRT RST',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 18,
    name: 'PULL STATION RST',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 19,
    name: 'ALARM ACTIVE',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 20,
    name: 'ALARM RESTORED',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 21,
    name: 'WATERFLOW ACTIVE',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 22,
    name: 'WATERFLOW RST',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 23,
    name: 'GND FAULT RST',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 24,
    name: 'TROUBLE OPEN RST',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 25,
    name: 'TROUBLE OPEN ACT',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 26,
    name: 'SUPERVISORY ACT',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 27,
    name: 'SUPERVISORY RST',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 28,
    name: 'GND FAULT ACTIVE',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 30,
    name: 'ACFAIL ACTIVE',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 31,
    name: 'ACFAIL RESTORE',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 35,
    name: 'AND GRP ACTIVE',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 36,
    name: 'AND GRP RST',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 39,
    name: 'NO TYPE',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 47,
    name: 'FAN RESTORED',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 52,
    name: 'DIS TRBL ACT',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 53,
    name: 'DIS TRBL RESTORE',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 56,
    name: 'DAMPER RESTORED',
    mapVisible: true,
    inspectionVisible: false,
  },
  {
    id: 57,
    name: 'HEAT ALARM RST',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 58,
    name: 'HEAT ALARM ACT',
    mapVisible: true,
    inspectionVisible: true,
  },
  {
    id: 59,
    name: 'RST',
    mapVisible: false,
    inspectionVisible: false,
  },
  {
    id: 60,
    name: 'ALARM VRF RST',
    mapVisible: false,
    inspectionVisible: false,
  },
];

export const groups: ContactGroup[] = [
  {
    name: 'AA JFK Users',
    id: 1,
  },
  {
    name: 'AA JFK Service',
    id: 2,
  },
];

export const buildings: Building[] = [
  {
    mode: AppMode.Normal,
    autoModeDeviceID: 0,
    siteId: 1,
    name: 'Terminal 8',
    id: 1,
    shortName: 'T8',
  },
  {
    mode: AppMode.Normal,
    autoModeDeviceID: 0,
    siteId: 1,
    name: 'Cargo 79',
    id: 2,
    shortName: 'C79',
  },
  {
    mode: AppMode.Normal,
    autoModeDeviceID: 0,
    siteId: 1,
    name: 'Hangar 10',
    id: 4,
    shortName: '',
  },
];
