import { SVGOverlay, WMSTileLayer, useMap } from 'react-leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import useApp from 'hooks/useApp';
import logger from 'utils/logger';
import { MapItem } from 'entities';
import { MapMarker } from './MapMarker';
import { DivIcon, LatLngBounds, SVGOverlay as LeafletSVGOverlay, Point } from 'leaflet';
import useAxios from 'hooks/useAxios';
import { MapType } from './MapControl';
import useMaps from 'hooks/useMaps';
import useNotification from 'hooks/useNotification';

// import MarkerClusterGroup from 'react-leaflet-cluster';

// import 'leaflet/dist/leaflet.css';

// import MARKERSDATA from 'assets/map/markers.json';

// type OldMarker = {
//   Address: string;
//   Symbol: string;
//   SymbolOffset: string;
//   Map: string;
//   X: string;
//   Y: string;
// };

// const oldMarkers: OldMarker[] = MARKERSDATA;

// eslint-disable-next-line
const createClusterCustomIcon = function (cluster: any) {
  return new DivIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: [33, 33],
  });
};

type MapLayersProps = {
  mapType: MapType;
  showMenu?: boolean;
};

export function MapLayers({ showMenu, mapType }: MapLayersProps) {
  const lmap = useMap();
  const { get, post } = useAxios();
  const { map, addMarker, selectedMapItem } = useApp();

  const mapRef = useRef(null);
  const labelOverlayRef = useRef<LeafletSVGOverlay>(null);

  const minZoom = 1;
  const maxZoom = 6;
  const [zoom, setZoom] = useState(minZoom);
  const [iconSize, setIconSize] = useState(5);

  const [mapId, setMapId] = useState(map?.id);
  const [markerOverlay, setMarkerOverlay] = useState<MapItem[]>();

  const overlayBounds = new LatLngBounds([0, 48], [-256, 208]);
  const [gridOverlay, setGridOverlay] = useState<string>();
  const [labelOverlay, setLabelOverlay] = useState<string>();

  const overlayHandlers = useMemo(() => ({}), []);

  useEffect(() => {
    if (selectedMapItem) {
      lmap.flyTo([selectedMapItem.x, selectedMapItem.y], 7);
      lmap.invalidateSize(true);
    }
  }, [selectedMapItem]);

  useEffect(() => {
    const efso = map && map?.name.indexOf('EFSO') > 0;
    const size = efso ? 30 : 10;
    const dimension = (efso ? size / 2 : zoom <= 5 ? 1 : zoom <= 6 ? 2 : zoom <= 7 ? 3 : zoom <= 8 ? size / 2 : size) * zoom; // (zoom < 6 ? 1 : zoom); // + (zoom < 7 ? 0 : zoom < 8 ? 100 : 200);
    setIconSize(dimension);
  }, [zoom, map]);

  useEffect(() => {
    setMarkerOverlay(map?.items ?? []);
  }, [map, map?.items]);

  useEffect(() => {
    logger.log('MapLayer.map', map);
    if (mapRef.current && map) {
      setMapId(map.id);
      // @ts-ignore
      mapRef.current.setUrl(map.url);
    }

    // only have this mouse event for adding markers
    if (mapType === 'general') {
      lmap.addEventListener('mouseup', (e) =>
        addMarker({
          x: e.latlng.lat,
          y: e.latlng.lng,
          mapID: map?.id,
          name: '',
          id: 0,
        })
      );
    }

    lmap.addEventListener('zoomend', () => setZoom(lmap.getZoom()));

    if (map) {
      // TODO get from cache
      // && map.id != mapId) {
      if (map?.showLabels)
        get<string>(`/api/map/overlay/${map.id}/labels`).then((svg) => {
          setLabelOverlay(svg);
        });

      if (map?.showGrid)
        get<string>(`/api/map/overlay/${map.id}/grid`).then((svg) => {
          setGridOverlay(svg);
        });
    }

    return () => {
      addMarker(undefined);
      lmap.removeEventListener('mouseup');
      lmap.removeEventListener('zoomend');
      setMarkerOverlay(undefined);
      setLabelOverlay(undefined);
      setGridOverlay(undefined);
    };
  }, [map]);

  return (
    <>
      <WMSTileLayer
        ref={mapRef}
        format='image/png'
        // minNativeZoom={0}
        maxNativeZoom={maxZoom}
        tms={true}
        url={map?.url ?? ''}
        minZoom={minZoom}
        maxZoom={maxZoom + 2}
        accessToken={'client-access-token'}
        errorTileUrl='empty.png'
      />
      {gridOverlay && map?.showGrid && (
        <SVGOverlay bounds={overlayBounds} eventHandlers={overlayHandlers}>
          <svg id={'gridOverlay'} dangerouslySetInnerHTML={{ __html: gridOverlay }} />
        </SVGOverlay>
      )}
      {labelOverlay && map?.showLabels && (
        <SVGOverlay ref={labelOverlayRef} bounds={overlayBounds} eventHandlers={overlayHandlers}>
          <svg id={'labelOverlay'} dangerouslySetInnerHTML={{ __html: labelOverlay }} />
        </SVGOverlay>
      )}
      {/* <MarkerClusterGroup
        //onClick={(e) => console.log('onClick', e)}
        iconCreateFunction={createClusterCustomIcon}
        maxClusterRadius={150}
        spiderfyOnMaxZoom={true}
        polygonOptions={{
          fillColor: '#ffffff',
          color: '#121212',
          weight: 5,
          opacity: 1,
          fillOpacity: 0.8,
        }}
        showCoverageOnHover={true}
      > */}
      {map?.showMarkers && markerOverlay?.map((m) => <MapMarker zoom={zoom} mapType={mapType} showMenu={showMenu} size={iconSize} item={m} key={m.id} />)}
      {/* </MarkerClusterGroup> */}
    </>
  );
}
