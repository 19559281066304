import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';

// third party
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import useNotification from 'hooks/useNotification';
import AnimateButton from 'components/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// types
import { StringColorProps } from 'types';
import useAxios from 'hooks/useAxios';

YupPassword(Yup); // extend yup

const ResetPasswordFrom = ({ ...others }) => {
  const theme = useTheme();
  const { post } = useAxios();
  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  const { success } = useNotification();
  const [searchParams] = useSearchParams();

  const [code, setCode] = React.useState<string | null>();
  const [email, setEmail] = React.useState<string | null>();

  const [showPassword, setShowPassword] = React.useState(false);
  const [strength, setStrength] = React.useState(0);
  const [level, setLevel] = React.useState<StringColorProps>();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  const resetPassword = async (password: string): Promise<{ code: string; description: string }[]> => {
    return await post<{ code: string; description: string }[]>(
      `/api/account/reset?email=${email}&password=${encodeURIComponent(password ?? '')}&token=${encodeURIComponent(code ?? '')}`
    );
  };

  useEffect(() => {
    console.log(searchParams);
    if (searchParams.get('code') && searchParams.get('email')) {
      setEmail(searchParams.get('email'));
      setCode(searchParams.get('code')?.replaceAll(' ', '+'));
    } else navigate('/');
  }, [searchParams]);

  useEffect(() => {
    changePassword('');
  }, []);

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .max(255)
          .required('Password is required')
          .min(8, 'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special')
          .minLowercase(1, 'password must contain at least 1 lower case letter')
          .minUppercase(1, 'password must contain at least 1 upper case letter')
          .minNumbers(1, 'password must contain at least 1 number')
          .minSymbols(1, 'password must contain at least 1 special character'),
        confirmPassword: Yup.string()
          .required('Confirm Password is required')
          .test('confirmPassword', 'Both Password must be match!', (confirmPassword, yup) => yup.parent.password === confirmPassword),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPassword(values.password).then((errors: { code: string; description: string }[]) => {
            if (scriptedRef.current) {
              if (errors && errors.length > 0) {
                setStatus({ success: false });
                setErrors({ submit: errors.map((e) => e.description).join('|') });
              } else {
                setStatus({ success: true });
                setSubmitting(false);

                success('Successfuly reset password.');

                setTimeout(() => {
                  navigate('/login', { replace: true });
                }, 1500);
              }
            }
          });
        } catch (err: any) {
          console.error(err);
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} {...others}>
          <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.customInput }}>
            <InputLabel htmlFor='outlined-adornment-password-reset'>Password</InputLabel>
            <OutlinedInput
              id='outlined-adornment-password-reset'
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              name='password'
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                changePassword(e.target.value);
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end' size='large'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{}}
            />
          </FormControl>
          {touched.password && errors.password && (
            <FormControl fullWidth>
              <FormHelperText error id='standard-weight-helper-text-reset'>
                {errors.password}
              </FormHelperText>
            </FormControl>
          )}
          {strength !== 0 && (
            <FormControl fullWidth>
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item>
                    <Box
                      sx={{
                        width: 85,
                        height: 8,
                        borderRadius: '7px',
                        bgcolor: level?.color,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle1' fontSize='0.75rem'>
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </FormControl>
          )}

          <FormControl fullWidth error={Boolean(touched.confirmPassword && errors.confirmPassword)} sx={{ ...theme.typography.customInput }}>
            <InputLabel htmlFor='outlined-adornment-confirm-password'>Confirm Password</InputLabel>
            <OutlinedInput
              id='outlined-adornment-confirm-password'
              type='password'
              value={values.confirmPassword}
              name='confirmPassword'
              label='Confirm Password'
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{}}
            />
          </FormControl>

          {touched.confirmPassword && errors.confirmPassword && (
            <FormControl fullWidth>
              <FormHelperText error id='standard-weight-helper-text-confirm-password'>
                {' '}
                {errors.confirmPassword}{' '}
              </FormHelperText>
            </FormControl>
          )}

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              {errors.submit.split('|').map((e, i) => (
                <FormHelperText key={i} error>
                  {e}
                </FormHelperText>
              ))}
            </Box>
          )}
          <Box sx={{ mt: 1 }}>
            <AnimateButton>
              <Button disableElevation disabled={isSubmitting} fullWidth size='large' type='submit' variant='contained' color='secondary'>
                Reset Password
              </Button>
            </AnimateButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordFrom;
