import { OptionsObject, enqueueSnackbar } from 'notistack';
import { ReactElement } from 'react';
// import { ToastOptions, TypeOptions, toast } from "react-toastify";

const useNotification = () => {
  const error = (message: string, options?: OptionsObject<'error'>) => enqueueSnackbar(message, { variant: 'error', ...options });
  const info = (message: string) => enqueueSnackbar(message, { variant: 'info' });
  const success = (message: string) => enqueueSnackbar(message, { variant: 'success' });
  const warning = (message: string, options?: OptionsObject<'warning'>) => enqueueSnackbar(message, { variant: 'warning', ...options });
  const notify = (html: ReactElement) => enqueueSnackbar(html, { variant: 'default' });

  return { error, info, success, warning, notify };

  // const error = (message: string, options?: ToastOptions<{}> | undefined) => toast.error(message, {position: toast.POSITION.BOTTOM_CENTER, ...options});
  // const info = (message: string, options?: ToastOptions<{}> | undefined) => toast.info(message, {position: toast.POSITION.BOTTOM_CENTER, ...options});
  // const success = (message: string, options?: ToastOptions<{}> | undefined) => toast.success(message, {position: toast.POSITION.BOTTOM_CENTER, ...options});
  // const warning = (message: string, options?: ToastOptions<{}> | undefined) => toast.warning(message, {position: toast.POSITION.BOTTOM_CENTER, ...options});

  // const notify = (element: React.ReactNode, type: TypeOptions,  options?: ToastOptions<{}> | undefined) => toast(element, {type: type, position: toast.POSITION.BOTTOM_CENTER, ...options})

  // return {error, info, success, warning, notify}
};

export default useNotification;
