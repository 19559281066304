import React from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputFieldAttributes } from '.';

export const InputField: React.FC<InputFieldAttributes> = ({ label, name, type, multiline, disabled, control }) => {
  const { register } = useFormContext();

  return (
    <section>
      <Controller
        name={name}
        control={control}
        //rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            {...register(name)}
            multiline={multiline}
            rows={multiline ? 6 : 1}
            // maxRows={6}
            style={{ width: '100%', margin: '5px' }}
            type={type}
            label={label}
            value={field.value ?? ''}
            variant='outlined'
            disabled={disabled}
          />
        )}
      />
    </section>
  );
};
