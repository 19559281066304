import { ReactNode, createContext, useEffect, useReducer } from 'react';
import { AppUser, Building, Map, MapItem, SignalState } from '../entities';
import Loader from 'components/Loader';
import useAxios from 'hooks/useAxios';
import { ActionMap, ActionTypes } from 'contexts/ActionTypes';
import { AuthResponse, setSession, verifyToken } from 'types/auth';
import { AppContextState, AppContextType } from 'types/app';
import AppStateConnector from 'connectors/AppStateConnector';
import { AppState } from 'entities/AppState';
import { useLocalStorage } from '@uidotdev/usehooks';
import SignalStateConnector from 'connectors/SignalStateConnector';
import { MarkerType } from 'components/maps/MapMarker';

type AppProviderProps = {
  children: ReactNode;
};

type AppPayload = {
  [ActionTypes.Login]: AppContextState;
  [ActionTypes.Logout]: undefined;

  [ActionTypes.StateUpdate]: AppState;

  [ActionTypes.AddMarker]: MapItem | undefined;
  [ActionTypes.UpdateMap]: Map | undefined;
  [ActionTypes.UpdateMaps]: Map[];
  [ActionTypes.ShowOnMap]: string | undefined;
  [ActionTypes.MoveToItem]: MapItem | undefined;
  [ActionTypes.OpenPopup]: number | undefined;
  [ActionTypes.MarkerType]: MarkerType;
  [ActionTypes.SignalStateUpdate]: SignalState;

  [ActionTypes.Error]: string;
  [ActionTypes.Reset]: boolean;
};

type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

const initialAppState: AppContextState = {
  isLoggedIn: false,
  markerType: 'All',
  isInitialized: false,
  user: undefined,
  appState: undefined,
  building: undefined,
  map: undefined,
  mapItem: undefined,
  selectedMapItem: undefined,
  client: undefined,
};

export const AppContext = createContext<AppContextType | null>(null);

const appReducer = (state: AppContextState, action: AppActions): AppContextState => {
  // TODO make hash map
  switch (action.type) {
    case ActionTypes.Login: {
      const state = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
      };
    }
    case ActionTypes.Logout: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: undefined,
        client: undefined,
        site: undefined,
      };
    }
    case ActionTypes.StateUpdate: {
      const appState = action.payload;

      // check if state is of current building
      return state.building && state.building?.id === appState.buildingId
        ? {
            ...state,
            appState: { ...appState },
          }
        : { ...state };
    }

    case ActionTypes.AddMarker: {
      const mapItem = action.payload!;
      return {
        ...state,
        mapItem: mapItem,
      };
    }

    case ActionTypes.UpdateMap: {
      const map = action.payload!;
      return {
        ...state,
        map: map,
      };
    }
    case ActionTypes.UpdateMaps: {
      const maps = action.payload!;
      const map = maps.find((m) => m.id === state.map?.id);
      return {
        ...state,
        building: {
          ...state.building!,
          maps: maps,
        },
        map,
      };
    }

    case ActionTypes.ShowOnMap: {
      const query = action.payload;
      if (!query) return { ...state, mapItemId: undefined };
      let mapItemId: number | undefined = undefined;
      const map = state.building?.maps?.find((m) => {
        mapItemId = m.items?.find((i) => i.device?.address?.toLowerCase() === query?.toLowerCase())?.id;
        return mapItemId !== undefined;
      });
      return { ...state, map: map ?? state.map, mapItemId: mapItemId };
    }

    case ActionTypes.MoveToItem: {
      const item = action.payload;
      if (!item) return { ...state, selectedMapItem: undefined };
      const map = state.building?.maps?.find((m) => m.id === item.mapID);
      return { ...state, map: map ?? state.map, selectedMapItem: item, mapItemId: item.device ? item.id : undefined };
    }

    case ActionTypes.MarkerType: {
      return { ...state, markerType: action.payload };
    }

    case ActionTypes.SignalStateUpdate: {
      const signalState = action.payload!;
      return {
        ...state,
        building: {
          ...state.building!,
          signalStates: [...(state.building?.signalStates?.filter((s) => s.id !== signalState.id) ?? []), signalState], // [...state.building?.signalStates!, signalState],
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { get, post } = useAxios();
  const { appStateEvents } = AppStateConnector();
  const { signalStateEvents } = SignalStateConnector();
  const [state, dispatch] = useReducer(appReducer, initialAppState);
  const [storedBuilding, setBuilding] = useLocalStorage<Building>('bs-building', undefined);

  const getMaps = async (buildingId?: number): Promise<Map[]> =>
    buildingId
      ? (await get<Map[]>(`/api/map/all/${buildingId}`)).map((m) => ({
          ...m,
          // set map options
          url: `${process.env.REACT_APP_API_BASEURL?.replace(/\/$/, '')}/api/map/tile/${m.id}/{z}/{x}/{y}.png`,
          showMarkers: (m.items?.length ?? 0) > 0,
          hasMarkers: (m.items?.length ?? 0) > 0,
          showLabels: true,
          hasLabels: true,
          showGrid: true,
          hasGrid: true,
        }))
      : [];

  const initApp = async () => {
    const user = await get<AppUser>('/api/account/me');
    // TODO get from last used cookie and or profile...
    const client = user?.contact?.clients?.at(0);
    const site = client?.sites?.at(0);
    const building = site?.buildings?.find((b) => b.name === 'Terminal 8');
    if (client && site && building) {
      setBuilding(building);
      const maps = await getMaps(building.id);
      const signalStates = await get<SignalState[]>('/api/signalState/active');
      dispatch({
        type: ActionTypes.Login,
        payload: {
          user,
          isLoggedIn: true,
          isInitialized: true,
          markerType: 'All',
          client: {
            ...state.client!,
            id: client.id,
            name: client.name,
          },
          site: {
            ...state.site!,
            id: site.id,
            name: site.name,
            code: site.code,
          },
          building: {
            ...state.building!,
            maps: maps,
            id: building.id,
            name: building.name,
            signalStates: signalStates,
          },
          map: maps.at(0), //find((m) => m.id === state.site?.current?.id)
          appState: {
            ...state.appState,
            buildingId: building.id,
            mode: building.mode,
          },
        },
      });
    } else {
      dispatch({
        type: ActionTypes.Login,
        payload: {
          user,
          isLoggedIn: true,
          isInitialized: true,
          markerType: 'All',
        },
      });
    }
  };

  const login = async (email: string, password: string) => {
    const { authorizationToken } = await post<AuthResponse>('/api/account/login', { userName: email, password: password });
    setSession(authorizationToken);
    if (authorizationToken) await initApp();
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: ActionTypes.Logout });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = () => {};

  // const loadMaps = async () => {
  //   console.log('loadMaps', state);
  //   const buildingId = state.site?.buildingId;
  //   if (buildingId) {
  //     const maps = await get<Map[]>(`/api/map/all/${buildingId}`);
  //     dispatch({
  //       type: ActionTypes.UpdateMaps,
  //       payload: maps.map((m) => ({ ...m, url: `${process.env.REACT_APP_API_BASEURL?.replace(/\/$/, '')}/api/map/tile/${m.id}/{z}/{x}/{y}.png` })),
  //     });
  //   }
  // };

  const setMap = (map?: Map) => {
    dispatch({ type: ActionTypes.UpdateMap, payload: map });
  };

  const showOnMap = (address?: string) => {
    dispatch({ type: ActionTypes.ShowOnMap, payload: address });
  };

  const moveToItem = (selected?: MapItem) => dispatch({ type: ActionTypes.MoveToItem, payload: selected });

  const refreshMaps = async () => {
    dispatch({ type: ActionTypes.UpdateMaps, payload: await getMaps(state.building?.id) });
  };

  const addMarker = (mapItem?: MapItem) => {
    dispatch({ type: ActionTypes.AddMarker, payload: mapItem });
  };

  const showMarkerType = (markerType: MarkerType) => {
    dispatch({ type: ActionTypes.MarkerType, payload: markerType });
  };

  useEffect(() => {
    appStateEvents((appState: AppState) => {
      dispatch({ type: ActionTypes.StateUpdate, payload: appState });
    });
    signalStateEvents((signalState: SignalState) => {
      dispatch({ type: ActionTypes.SignalStateUpdate, payload: signalState });
    });
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && verifyToken(accessToken)) {
          await initApp();
        } else {
          dispatch({
            type: ActionTypes.Logout,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ActionTypes.Logout,
        });
      }
    };

    init();
  }, []);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={{ ...state, login, logout, resetPassword, updateProfile, setMap, showOnMap, moveToItem, refreshMaps, addMarker, showMarkerType }}>
      {children}
    </AppContext.Provider>
  );
};
