export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum ActionTypes {
  // auth actions
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Reset = 'RESET',

  // app state actions
  StateUpdate = 'STATE_UPDATE',

  // map actions
  AddMarker = 'ADD_MARKER',
  UpdateMap = 'MAP_UPDATE',
  UpdateMaps = 'MAPS_UPDATE',
  ShowOnMap = 'SHOW_ON_MAP',
  MoveToItem = 'MOVE_TO_ITEM',
  OpenPopup = 'OPEN_POPUP',
  MarkerType = 'MARKER_TYPE',
  SignalStateUpdate = 'SIGNAL_STATE_UPDATE',

  // global
  Error = 'ERROR',
  Loading = 'LOADING',
}
