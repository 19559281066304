import { CRS } from 'leaflet';
import { MapLayers } from 'components/maps/MapLayers';
import { MapContainer } from 'react-leaflet';
import MapSelector from './MapSelector';
import MapMenu from './MapMenu';
import MinimapControl from './MiniMap';
import { useWindowSize } from '@uidotdev/usehooks';

export type MapType = 'alarm' | 'inspection' | 'general';

interface MapControlProps {
  height?: string | number;
  showMenu?: boolean;
  mapType: MapType;
}

export const MAPZOOM = 2;
export const MAPCENTER = {
  lat: -128,
  lng: 128,
};

const MapControl = ({ height, showMenu, mapType }: MapControlProps) => {
  const { width } = useWindowSize();

  return (
    <MapContainer
      trackResize={true}
      crs={CRS.Simple}
      zoom={MAPZOOM}
      center={MAPCENTER}
      scrollWheelZoom={(width ?? 900) >= 900}
      style={{ height: height ?? '60vh' }} //(width ?? 900) >= 900 ? '50vh' : '60vh' }}
    >
      <MapLayers mapType={mapType} showMenu={showMenu} />
      <MapSelector />
      {showMenu && <MapMenu />}
      {(width ?? 900) >= 900 && <MinimapControl width={(width ?? 900) > 900 ? 180 : 80} height={(width ?? 900) > 900 ? 180 : 80} />}
    </MapContainer>
  );
};

export default MapControl;
