import { AddLocation } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, FormControl, TextField, Divider } from '@mui/material';
import { Device } from 'entities';
import { MapItem } from 'entities';
import useAxios from 'hooks/useAxios';
import { LatLng } from 'leaflet';
import { useState, useEffect } from 'react';
import { MenuItemProps } from '..';
import useApp from 'hooks/useApp';

interface AddItemProps extends MenuItemProps {
  position?: LatLng;
}

export const AddItem = ({ position, onClick }: AddItemProps) => {
  const { map, refreshMaps } = useApp();
  const { get, post } = useAxios();

  // dialog props
  const [dialogOpen, setDialogOpen] = useState(false);

  // autocomplete props
  const [value, setValue] = useState('');
  const [device, setDevice] = useState<Device>();
  //   const [position, setPosition] = useState<LatLng>();
  const [devices, setDevices] = useState<Device[]>([]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    onClick();
  };

  //   const handleAddMarker = (position?: LatLng) => {
  //     if (position) {
  //       setPosition(position);
  //       setDialogOpen(true);
  //     }
  //   };

  const handleDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (map && position) {
      const mapId = map.id;
      const mapItem: MapItem = { id: 0, x: position.lat, y: position.lng, mapID: mapId, name: device ? '' : value, deviceId: device?.id };
      post(`/api/mapItem/`, mapItem).then(() => {
        refreshMaps();
        handleDialogClose();
        setDevices([]);
        setDevice(undefined);
        onClick();
        // setPosition(undefined);
        // setContextMenu(null);
      });
    }
  };

  useEffect(() => {
    if (value.length >= 3) get<Device[]>('/api/device/query', { params: { search: value } }).then(setDevices);
    else setDevices([]);
  }, [value]);

  return (
    <>
      <MenuItem onClick={() => setDialogOpen(true)}>
        <ListItemIcon>
          <AddLocation fontSize='small' />
        </ListItemIcon>
        <ListItemText>Add marker</ListItemText>
      </MenuItem>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleDialogSubmit,
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 300 }}>
              <Autocomplete
                freeSolo
                autoFocus
                id='device'
                disableClearable
                options={devices}
                filterOptions={(x) => x}
                onChange={(_, v) => setDevice(typeof v !== 'string' ? v : undefined)}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.address})`)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    required
                    focused={true}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    label='Set label or device'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </FormControl>
            {device && (
              <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
                <Divider />
                <TextField disabled label='Name' defaultValue={device?.name} />
                <TextField disabled label='Address' defaultValue={device?.address} />
                <TextField disabled label='Descrition' multiline={true} minRows={2} defaultValue={device?.description} />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button type='submit'>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
