import { Box, Button, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { useState } from 'react';
import PulseList from './PulseList';
import { Building, Client, NotificationGroup, Notification } from 'entities';
import PulseInfo from './PulseInfo';
import useNotification from 'hooks/useNotification';

const PulsePanel = () => {
  const { success } = useNotification();
  const [preview, setPreview] = useState(true);

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleOnSend = () => {
    const d = new Date();
    setMessage('');
  };

  const onPreviewClick = () => {
    setPreview(false);
  };

  const onSendClick = () => {
    setPreview(true);
    success(`Send pulse '${subject}' `);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.key !== 'Enter') {
      return;
    }
    handleOnSend();
  };

  return (
    <Box component='div' sx={{ width: '100%', height: '100%' }}>
      <Grid container>
        <Grid item xs={12} sm zeroMinWidth>
          {preview && (
            <>
              <PulseInfo />
              <Box display={'flex'} flexDirection={'row'} gap={3}>
                <OutlinedInput
                  id='subject-send'
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  onKeyPress={handleEnter}
                  placeholder='Subject'
                  aria-describedby='search-helper-text'
                  inputProps={{ 'aria-label': 'weight' }}
                />
                <OutlinedInput
                  id='message-send'
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleEnter}
                  placeholder=''
                  aria-describedby='search-helper-text'
                  inputProps={{ 'aria-label': 'weight' }}
                />
                <Button variant='contained' color={'primary'} onClick={onPreviewClick} sx={{ px: 0.75 }}>
                  {preview ? 'Preview' : 'Send'}
                </Button>
              </Box>
            </>
          )}
          {!preview && (
            <>
              <Box display={'flex'} flexDirection={'column'} gap={3}>
                <OutlinedInput placeholder={'Subject'} value={subject} disabled />
                <OutlinedInput placeholder={'BCC & SMS receipts'} value={['Test@bs.com', '170187273', ''].join('; ')} disabled />
                <OutlinedInput
                  placeholder={message}
                  multiline
                  rows={5}
                  value={`${message}\r\nGND FAULT ACTIVE  ::  01:39:58 09/01/2024  P:17  C:05  D:0677 17050677Ground Fault DataCard1\r\nPulse #0003 - Beacon-Suite team`}
                  disabled
                />
              </Box>
              <Button variant='contained' color={'secondary'} onClick={onSendClick} sx={{ px: 0.75 }}>
                SEND
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PulsePanel;
