import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import LoginForm from '../../components/forms/LoginForm';
import { AuthWrapper } from './AuthWrapper';

const Login = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthWrapper>
      <Grid item xs={12}>
        <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems='center' justifyContent='center'>
          <Grid item>
            <Stack alignItems='center' justifyContent='center' spacing={1}>
              <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                Hi, Welcome Back
              </Typography>
              <Typography variant='caption' fontSize='16px' textAlign={matchDownSM ? 'center' : 'inherit'}>
                Enter your credentials to continue
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoginForm />
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
