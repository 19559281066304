import { RouterProvider } from 'react-router-dom';

// routing
import { router } from 'AppRoutes';

// project imports
import Locales from 'components/Locales';
import NavigationScroll from 'components/NavigationScroll';

import ThemeCustomization from 'themes';

// style + assets
import 'assets/scss/style.scss';

// google-fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/700.css';

import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

// providers
import { SnackbarProvider } from 'notistack';
import { AppProvider } from 'contexts/AppContext';
import { ConfigProvider } from 'contexts/ConfigContext';
import { MapProvider } from 'contexts/MapContext';
import { ErrorBoundary } from 'components/ErrorBoundery';

// ==============================|| APP ||============================== //

const App = () => {
  return (
    <ErrorBoundary>
      <SnackbarProvider maxSnack={3}>
        <AppProvider>
          <MapProvider>
            <ConfigProvider>
              <ThemeCustomization>
                <Locales>
                  <NavigationScroll>
                    <RouterProvider router={router} />
                  </NavigationScroll>
                </Locales>
              </ThemeCustomization>
            </ConfigProvider>
          </MapProvider>
        </AppProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

export default App;
