import { ReactNode, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import logger from '../utils/logger';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { DataGrid } from '../components/dataGrid/DataGrid';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import { IntervalType } from '../entities/InspectionType';
import MainCard from 'components/cards/MainCard';
import { DeviceGroup } from 'entities';

function RenderActionCell(params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>): ReactNode {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(params.row.excluded ?? false);

  const handleEdit = (id: number) => {
    logger.log('handleEdit(%s)', id);
    navigate(`/types/inspection/${id}`);
  };

  //logger.log(params);
  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton size='small' aria-label='edit' color='inherit' onClick={() => handleEdit(params.row.id)}>
        <EditIcon />
      </IconButton>
    </Stack>
  );
}

export default function InspectionTypes() {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'edit',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
    { field: 'name', headerName: 'Name', width: 400, filterable: false },
    {
      field: 'groups',
      headerName: 'System(s)',
      width: 400,
      filterable: false,
      renderCell(params): ReactNode {
        const deviceGroups = params.row.deviceGroups as DeviceGroup[];
        return <>{deviceGroups?.map((g) => g.name).join(', ') ?? '-'}</>;
      },
    },
    {
      field: 'interval',
      headerName: 'Interval',
      width: 200,
      filterable: false,
      renderCell(params): ReactNode {
        return <>{params.row.intervalValue ? `every ${params.row.intervalValue} ${IntervalType[params.row.intervalType]}` : 'N/A'}</>;
      },
    },
  ];

  return (
    <Grid item xs={12}>
      <MainCard
        title='Inspection Types'
        secondary={
          <Button variant='contained' onClick={() => navigate('/types/inspection/0')}>
            Add
          </Button>
        }
      >
        <DataGrid dataUrl={'/api/inspectionType/query'} columnDefinitions={columns} />
      </MainCard>
    </Grid>
  );
}
