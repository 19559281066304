import { useState, useEffect } from 'react';
import { Photo } from 'entities';
import PhotoAlbum from 'react-photo-album';

import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

// TODO move to assets/scss
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import useAxios from 'hooks/useAxios';
import useApp from 'hooks/useApp';

export default function PhotosGallery() {
  const { get } = useAxios();
  const { building } = useApp();
  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState<Photo[]>([
    {
      src: 'Gallery/photo_2023-09-27_13-07-03.jpg',
      name: '',
      id: 1,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 1280,
      height: 960,
    },
    {
      src: 'Gallery/photo_2023-09-27_13-07-57.jpg',
      name: '',
      id: 2,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 1280,
      height: 960,
    },
    {
      src: 'Gallery/photo_2024-01-08_13-45-56.jpg',
      name: '',
      id: 3,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 1280,
      height: 960,
    },
    {
      src: 'Gallery/photo_2024-05-07_17-02-16.jpg',
      name: '',
      id: 4,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 598,
      height: 1280,
    },
    {
      src: 'Gallery/photo_2024-05-15_21-53-33.jpg',
      name: '',
      id: 5,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 1280,
      height: 598,
    },
    {
      src: 'Gallery/photo_2024-05-16_21-21-53.jpg',
      name: '',
      id: 6,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 598,
      height: 1280,
    },
    {
      src: 'Gallery/photo_2024-06-11_12-31-45.jpg',
      name: '',
      id: 0,
      author: {
        userName: 'Admin',
        id: '',
        isAdmin: false,
        email: '',
        contactId: 0,
        role: '',
      },
      width: 1280,
      height: 598,
    },
  ]);

  useEffect(() => {
    // if (building) get<Photo[]>(`/api/photo/all/${building.id}`).then(setPhotos);
  }, []);

  return (
    <>
      <PhotoAlbum
        layout='rows'
        photos={photos}
        onClick={({ index }) => setIndex(index)}
        renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => {
          return (
            <a href={'#'} style={wrapperStyle}>
              {renderDefaultPhoto({ wrapped: true })}
            </a>
          );
        }}
      />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  );
}
