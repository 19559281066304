import { ReactNode, useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Link, Box, IconButton, Stack } from '@mui/material';
import useNotification from 'hooks/useNotification';
import SignalConnector from 'connectors/SignalConnector';
import { Device, Signal } from 'entities';
import { DataGrid } from 'components/dataGrid/DataGrid';
import useApp from 'hooks/useApp';
import useSignals from 'hooks/useSignals';
import Map from '@mui/icons-material/Map';
import useAxios from 'hooks/useAxios';
import logger from 'utils/logger';

type SignalsTableProps = {
  filter: boolean;
  showActions?: boolean;
  activeOnly: boolean;
  pageSizeOptions?: number[];
};

function RenderActionCell(params: GridRenderCellParams<Device, any, any, GridTreeNodeWithRender>): ReactNode {
  const navigate = useNavigate();
  const { showOnMap } = useApp();

  const handleEdit = (id: number) => {};

  //logger.log(params);
  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton
        size='small'
        title='Show on map'
        color='inherit'
        onClick={() => {
          navigate('/maps');
          showOnMap(params.row.address);
        }}
      >
        <Map />
      </IconButton>
    </Stack>
  );
}

export default function SignalsTable({ activeOnly, filter, pageSizeOptions, showActions }: SignalsTableProps) {
  const apiRef = useGridApiRef();
  const { building } = useApp();
  const { states } = useSignals();
  const { warning } = useNotification();

  const [reload, setReload] = useState(false);

  const { signalEvents } = SignalConnector();
  const [showNotification, setShowNotification] = useState(false);

  const renderTypeCell = (params: GridRenderCellParams<Signal, any, any, GridTreeNodeWithRender>): ReactNode => {
    return (
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginRight: '10px',
        }}
      >
        {params.row.signalType?.name}
      </Box>
    );
  };

  const actionColumns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 60,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
  ];

  const columns: GridColDef[] = [
    ...(showActions ? actionColumns : []),
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'signalType',
      headerName: 'Type',
      filterable: true,
      width: 200,
      renderCell: renderTypeCell,
    },
    {
      field: 'message',
      headerName: 'Signal',
      width: 200,
      minWidth: 200,
      filterable: false,
      flex: 0.3,
      renderCell(params): ReactNode {
        var message = (params.value as string)?.replaceAll('_', ' ');
        return <div title={message}>{message}</div>;
      },
    },
    {
      field: 'device',
      headerName: 'Address',
      width: 150,
      filterable: false,
      renderCell(params) {
        return params.row.deviceID ? (
          <Link component={RouterLink} to={`/device/${params.row.deviceID}`} sx={{ textDecoration: 'underline' }}>
            {params.row.device?.address ?? ''}
          </Link>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'type',
      headerName: 'System',
      filterable: true,
      width: 200,
      renderCell(params) {
        return params.row.device?.group?.name ?? params.value;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.deviceID ? (
          <Link component={RouterLink} to={`/device/${params.row.deviceID}`}>
            {params.value ?? ''}
          </Link>
        ) : (
          params.value ?? ''
        );
      },
    },
  ];

  // useEffect(() => {
  //   setShowNotification(false);
  // }, [reloadData]);

  useEffect(() => {
    setReload(!reload);
  }, [activeOnly]);

  useEffect(() => {
    signalEvents((signal: Signal) => {
      // only update when its not filtered or filtered belonging to a building
      if ((filter && building && signal.device?.location && signal.device?.location?.buildingID === building?.id) || !filter) {
        //apiRef.current.updateRows([signal]);
        setReload(!reload);
      }
    });
  });

  return activeOnly ? (
    <DataGrid
      api={apiRef}
      reload={reload}
      hideHeader={false}
      pageSizeOptions={pageSizeOptions}
      rows={states.length > 0 ? states.map((s) => s.signalActivated) : []}
      orderBy={'date desc'}
      columnDefinitions={columns}
    />
  ) : (
    <DataGrid
      api={apiRef}
      reload={reload}
      hideHeader={false}
      pageSizeOptions={pageSizeOptions}
      dataUrl={filter && building?.id ? `/api/signal/query/${building?.id}` : '/api/signal/query'}
      orderBy={'date desc'}
      columnDefinitions={columns}
    />
  );
}
