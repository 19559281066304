import * as signalR from '@microsoft/signalr';
import { Signal } from '../entities/Signal';
import logger from 'utils/logger';

const URL = `${process.env.REACT_APP_API_BASEURL}/hub`;

class SignalConnector {
  private connection: signalR.HubConnection;
  public signalEvents: (onSignalRaised: (signal: Signal) => void) => void;
  static instance: SignalConnector;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build();
    this.connection.start().catch((err) => logger.logError('FAILED to start SignalConnector:', err));
    this.signalEvents = (onSignalRaised) => {
      this.connection.on('newSignal', (signal: Signal) => {
        onSignalRaised(signal);
      });
    };
  }
  // not needed, send from function app updates (Watcher Service)
  // public notify = (signal: Signal) => {
  //     this.connection.send("newSignal", signal).then(x => logger.log("Notified NewSignal:", signal))
  // }
  public static getInstance(): SignalConnector {
    if (!SignalConnector.instance) SignalConnector.instance = new SignalConnector();
    return SignalConnector.instance;
  }
}

export default SignalConnector.getInstance;
