import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';

// assets
import UploadCover from 'assets/images/upload/upload.svg';

// types
import { DropzopType } from 'types/dropzone';

// ==============================|| UPLOAD - PLACEHOLDER ||============================== //

export default function PlaceholderContent({ type }: { type?: string }) {
  return (
    <>
      {type !== DropzopType.standard && (
        <Stack spacing={2} alignItems='center' justifyContent='center' direction={{ xs: 'column', md: 'row' }} sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}>
          <CardMedia component='img' image={UploadCover} sx={{ width: 150 }} />
          <Stack sx={{ p: 3 }} spacing={1}>
            <Typography variant='h5'>Drag & Drop or Select file</Typography>

            <Typography color='secondary.main'>Drop files here or click to select.</Typography>
          </Stack>
        </Stack>
      )}
      {type === DropzopType.standard && (
        <Stack alignItems='center' justifyContent='center' sx={{ height: 1 }}>
          <CameraAltOutlinedIcon style={{ fontSize: '32px' }} />
        </Stack>
      )}
    </>
  );
}
