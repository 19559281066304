import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import * as yup from 'yup';
import { gridSpacing } from 'config';
import MultiFileUpload from './MultiFileUpload';

const Dropzone = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Formik
          initialValues={{ files: null }}
          onSubmit={(values: any) => {
            // submit form
          }}
          validationSchema={yup.object().shape({
            files: yup.mixed().required('Photo is a required.'),
          })}
        >
          {({ values, handleSubmit, setFieldValue, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1.5} alignItems='center'>
                    <MultiFileUpload setFieldValue={setFieldValue} files={values.files} error={touched.files && !!errors.files} />
                  </Stack>
                  {touched.files && errors.files && (
                    <FormHelperText error id='standard-weight-helper-text-password-login'>
                      {errors.files as string}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        {/* </MainCard> */}
      </Grid>
    </Grid>
  );
};

export default Dropzone;
