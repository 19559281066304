import { Button, Grid, Box } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { FieldAttributes, Field, FieldType } from '../components/fields';
import { InspectionType } from '../entities/InspectionType';
import useAxios from 'hooks/useAxios';
import MainCard from 'components/cards/MainCard';
import InspectionTypeForm from 'components/forms/InspectionTypeForm';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export default function InspectionTypeView() {
  const navigate = useNavigate();
  const params = useLoaderData() as string;
  const { get } = useAxios();

  const [id, setId] = useState<number>();
  const [entity, setEntity] = useState<InspectionType>();

  useEffect(() => {
    if (id && id > 0) {
      get<InspectionType>(`/api/inspectionType/${id}`).then(setEntity);
    }
  }, [id]);

  useEffect(() => {
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  return (
    <Grid item xs={12}>
      <MainCard title={entity ? `Edit inspection type` : 'New inspection type'}>
        <InspectionTypeForm entity={entity} />
      </MainCard>
    </Grid>
  );
}
