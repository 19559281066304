import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { LookupFieldAttributes } from '.';

import logger from '../../utils/logger';
import { AxiosResponse } from 'axios';
import { NamedEntity } from '../../entities/base/Entity';
import useAxios from 'hooks/useAxios';

export const LookupField: React.FC<LookupFieldAttributes> = ({ label, name, dataUrl, disabled, control }) => {
  const { get } = useAxios();
  const { register, setValue } = useFormContext();
  const [options, setOptions] = useState<NamedEntity[]>([]);

  // const renderOptions = (response: AxiosResponse) => {
  //   logger.log('LOOKUP [%s] data: ', label.toUpperCase(), response.data);
  //   const data = response.data as Entity[];
  //   setOptions(data); //[{label: '', id: 0}, ...(data?.map(e => { return {label: e.name, id: e.id}}) ?? [])]);//data?.map(e => { return {label: e.name, id: e.id}}) ?? [])
  // };

  useEffect(() => {
    get<NamedEntity[]>(dataUrl).then(setOptions);
  }, [dataUrl]);

  return (
    <section>
      <Controller
        name={name}
        control={control}
        //rules={{ required: true }}
        disabled={disabled}
        render={({ field }) => (
          <Autocomplete
            {...field}
            {...register(name)}
            options={options}
            freeSolo={true}
            value={field.value}
            style={{ width: '100%', margin: '5px' }}
            renderInput={(params) => <TextField {...params} label={label} itemID={params.id} />}
            getOptionLabel={(option) => options.find((o) => o.id === option)?.name ?? option.name ?? ''}
            onChange={(e, v) => {
              if (v && v.id) setValue(name, v.id);
              return v;
            }}
          />
        )}
      />
    </section>
  );
};
