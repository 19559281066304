import { NamedEntity } from './base/Entity';
import { QuestionEntity } from './QuestionEntity';
import { DeviceGroup } from './DeviceGroup';
import { Device } from './Device';

export enum IntervalType {
  Week,
  Month,
  Year,
}

export interface InspectionType extends NamedEntity {
  intervalValue?: number;
  intervalType?: number;
  autoModeId: number;
  autoModeDevice?: Device;
  deviceGroups?: DeviceGroup[];
  questions?: QuestionEntity[];
}
