import { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItem, ListItemText, Grid, Typography, Divider, Avatar, Badge, ListItemAvatar, CircularProgress } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Device } from 'entities';
import useApp from 'hooks/useApp';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import useAxios, { PagedResult, QueryOptions } from 'hooks/useAxios';
import { debounce } from 'lodash';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import MARKERSDATA from 'assets/map/markers.json';

type OldMarker = {
  Address: string;
  Symbol: string;
  SymbolOffset: string;
  Map: string;
  X: string;
  Y: string;
};

const oldMarkers: OldMarker[] = MARKERSDATA;

type DeviceListProps = {
  type: 'Unmapped' | 'Uninspected';
};

type ItemProps = {
  item: Device;
  dnd: DraggableProvided;
  isDragging: boolean;
};

function Item({ item, dnd, isDragging }: ItemProps) {
  return (
    <Fragment>
      <ListItem title={item.location?.name ?? item.name} ref={dnd.innerRef} {...dnd.draggableProps} {...dnd.dragHandleProps}>
        <ListItemAvatar>
          <Badge
            overlap='circular'
            //badgeContent={<AvatarStatus status={user.online_status!} />} // TODO set
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Avatar alt={item.name}>
              <img alt={item.name} src={`/icons/devices/${item.deviceType?.iconName}`} width={35} />
            </Avatar>
          </Badge>
        </ListItemAvatar>
        {!isDragging && (
          <ListItemText
            primary={
              <Grid container alignItems='center' spacing={1} component='span'>
                <Grid item xs zeroMinWidth component='span'>
                  <Typography
                    variant='h5'
                    color='inherit'
                    component='span'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {item.name.replaceAll('_', ' ')}
                  </Typography>
                </Grid>
                <Grid item component='span'>
                  {item.address}
                </Grid>
              </Grid>
            }
            secondary={
              <Grid container alignItems='center' spacing={1} component='span'>
                <Grid item xs zeroMinWidth component='span'>
                  <Typography
                    variant='caption'
                    component='span'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {item.group?.name} | {item.type} | {item.location?.name}
                  </Typography>
                </Grid>
                <Grid item component='span'>
                  {/* {user.unReadChatCount !== 0 && (
              <Chip
                label={user.unReadChatCount}
                component='span'
                color='secondary'
                sx={{
                  width: 20,
                  height: 20,
                  '& .MuiChip-label': {
                    px: 0.5,
                  },
                }}
              />
            )} */}
                </Grid>
              </Grid>
            }
          />
        )}
      </ListItem>
      <Divider />
    </Fragment>
  );
}

export default function DeviceList({ type }: DeviceListProps) {
  const { map } = useApp();
  const theme = useTheme();

  const { get, getPaged } = useAxios();
  const { building } = useApp();

  const [value, setValue] = useState('');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Device[]>();
  const [totalItemCount, setTotalItemCount] = useState<number>();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const debouncedSearch = debounce(setQuery, 1000);

  const renderData = (result?: PagedResult<Device[]>) => {
    if (result) {
      setItems(result.items ?? []);
      setTotalItemCount(result.pagination.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (type && query) {
      setLoading(true);
      setTotalItemCount(undefined);
      // get<Device[]>(`/api/device/${type}/${query}`).then((d) => {
      //   setItems(d);
      //   setLoading(false);
      // });

      const options: QueryOptions = {
        pageSize: 100,
        page: 0,
        search: query,
      };
      setLoading(true);
      getPaged<Device[]>(`/api/device/unmapped`, { params: options }).then(renderData);
    }
  }, [map, query]);

  useEffect(() => {
    const delimiter = (map?.name ?? '').indexOf('-') > 0 ? '-' : ' ';
    const value = map?.name.split(delimiter).reverse()[0]?.trim().replace('First', '1st').replace('Second', '2nd').replace('Third', '3rd').replace('Fourth', '4th') ?? '';
    if (value !== query) {
      setValue(value);
      debouncedSearch(value);
    }
  }, [map]);

  return (
    <>
      <OutlinedInput
        fullWidth
        id='input-search-header'
        placeholder='Search Devices'
        value={value}
        type='new-password'
        onChange={(e) => {
          const value = e.target.value;
          setValue(value);
          debouncedSearch(value);
        }}
        startAdornment={
          <InputAdornment position='start'>
            <SearchTwoToneIcon fontSize='small' />
          </InputAdornment>
        }
      />
      <PerfectScrollbar
        style={{
          marginTop: 5,
          overflowX: 'hidden',
          height: downLG ? 'calc(100vh - 190px)' : '65vh',
          minHeight: downLG ? 0 : 300,
        }}
      >
        <Box sx={{ p: 1, pt: 0 }}>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
              <CircularProgress />
            </Box>
          )}
          {!loading &&
            items?.map((d) => (
              <Draggable key={d.id} draggableId={d.id.toString()} index={d.id}>
                {(provided, snapshot) => <Item item={d} key={d.id} dnd={provided} isDragging={snapshot.isDragging} />}
              </Draggable>
            ))}
        </Box>
      </PerfectScrollbar>
      <Divider />
      {items && totalItemCount && items.length < totalItemCount && (
        <sub>
          Showing {items.length} devices out of {totalItemCount}, please narrow your query.
        </sub>
      )}
    </>
  );
}
