import InspectionConnector from 'connectors/InspectionConnector';
import { Inspection, Device, InspectionResult } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { InspectionState } from 'entities/enums/InspectionState';

// export type InspectionResultType = 'All' | 'Signaled' | 'Visibility' | 'Inspected' | 'Uninspected';

const useInspection = () => {
  const { get, patch } = useAxios();
  const { inspectionEvents } = InspectionConnector();

  // const [name, setName] = useState('');
  const [inspection, setInspection] = useState<Inspection>();

  // const [inspectionDevices, setInspectionDevices] = useState<Device[]>();
  // const [inspectionResults, setInspectionResults] = useState<InspectionResult[]>();

  const getInspection = (id?: number) => id && id > 0 && get<Inspection>(`/api/inspection/${id}`).then(initInspection);

  const initInspection = (i: Inspection) => {
    setInspection(i);
    // if (!inspectionDevices) get<Device[]>(`/api/inspection/${i.id}/devices`).then(setInspectionDevices);
  };

  const handleState = async (id: number) => {
    const item = inspection?.id === id ? inspection : await get<Inspection>(`/api/inspection/${id}`);
    if (item && !item.endDate) {
      const alternateState = item.state === InspectionState.Paused ? InspectionState.InProgress : InspectionState.Paused;
      // const result = await getConfirmation(`Are you sure you want to ${alternateState === InspectionState.InProgress ? 'RESUME' : 'PAUSE'} this inspection`);
      // if (result)
      await patch(`/api/inspection/${item.id}/state/${alternateState}`);
      //handleInspectionSubmit({ ...inspection, state: alternateState });
    }
  };

  useEffect(() => {
    console.log('INSPECTION UPDATED');
    inspectionEvents(setInspection); //(i) => getInspection(i.id));
  }, [inspectionEvents]);

  return { inspection, handleState, getInspection };
};

export default useInspection;
