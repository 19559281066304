import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AuthWrapper } from './AuthWrapper';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';

const ForgotPassword = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthWrapper>
      <Grid item xs={12}>
        <Grid container alignItems='center' justifyContent='center' textAlign='center' spacing={2}>
          <Grid item xs={12}>
            <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
              Forgot password?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='caption' fontSize='16px' textAlign='center'>
              Enter your email address below and we&apos;ll send you password reset OTP.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ForgotPasswordForm />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid item container direction='column' alignItems='center' xs={12}>
          <Typography component={Link} to={'/login'} variant='subtitle1' sx={{ textDecoration: 'none' }}>
            Already have an account?
          </Typography>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ForgotPassword;
