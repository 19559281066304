import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemProps } from '..';

export const DeleteItem = ({ onClick }: MenuItemProps) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <Delete fontSize='small' />
      </ListItemIcon>
      <ListItemText>Delete marker</ListItemText>
    </MenuItem>
  );
};
