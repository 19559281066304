import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/ClientTitle';

const Settings = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard title={<ClientTitle prefix={'Settings for'} showClient={true} />}>
        
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Settings;
