import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectFieldAttributes } from '.';

export interface SelectOption {
  label: string;
  value: any;
}

export const SelectField: React.FC<SelectFieldAttributes> = ({ label, name, options, disabled, control }) => {
  const { register, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      //rules={{ required: true }}
      disabled={disabled}
      render={({ field }) => (
        <FormControl {...field} {...register(name)} sx={{ width: '100%' }}>
          <InputLabel id={label.toLowerCase().replaceAll(' ', '_')}>{label}</InputLabel>
          <Select
            label={label}
            labelId={label.toLowerCase().replaceAll(' ', '_')}
            //defaultValue={''}
            style={{ width: '100%', margin: '5px' }}
            value={field.value}
            name={name}
            placeholder={`Select ${label}`}
            onChange={(e) => {
              setValue(e.target.name, e.target.value); //, {shouldTouch: false})
              return e;
            }}
          >
            {options?.map((s, i) => (
              <MenuItem key={i} value={s.value}>
                {s.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
