import { Grid } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/ClientTitle';
import NotificationRelayTable from 'components/collections/NotificationRelayTable';

const Notifications = () => {
  return (
    <MainCard title={<ClientTitle prefix={'Notifications for'} showClient />}>
      <NotificationRelayTable />
    </MainCard>
  );
};

export default Notifications;
