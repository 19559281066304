import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid';
import MainCard from 'components/cards/MainCard';
import InspectionsTable from 'components/collections/InspectionsTable';
import { Button } from '@mui/material';
import { ClientTitle } from 'components/ClientTitle';
import { useNavigate } from 'react-router-dom';

const Inspections = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={<ClientTitle prefix={'Inspection for'} />}
          secondary={
            <Button variant='contained' onClick={() => navigate('/inspection/0')}>
              {'New Inspection'}
            </Button>
          }
        >
          <InspectionsTable />
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Inspections;
