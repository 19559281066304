import { Button, Grid, Box } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { FieldAttributes, Field, FieldType } from '../../components/fields';
import { InspectionType } from '../../entities/InspectionType';
import useAxios from 'hooks/useAxios';

const InitialState: InspectionType = {
  id: 0,
  name: '',
  questions: [],
  autoModeId: 0,
  deviceGroups: [],
  intervalType: 0,
  intervalValue: 0,
};

type InspectionTypeFormProps = {
  entity?: InspectionType;
};

export default function InspectionTypeForm({ entity }: InspectionTypeFormProps) {
  const navigate = useNavigate();
  const params = useLoaderData() as string;
  const { get, put, post } = useAxios();

  const [id, setId] = useState<number>();
  const [state, setState] = useState(entity);

  const methods = useForm<InspectionType>({
    defaultValues: useMemo(() => {
      return state ?? InitialState;
    }, [state]),
  });

  const onSubmit: SubmitHandler<InspectionType> = (data) => {
    const id = data.id;
    //TODO implement formatter
    // delete data.deviceGroups;
    // delete data.questions;
    console.log('INSPECTIONTYPE', data);
    if (id && id > 0) {
      put(`/api/inspectionType/${id}`, data).then(() => navigate('/types/inspection'));
    } else {
      post(`/api/inspectionType`, data).then(() => navigate('/types/inspection'));
    }
  };

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      span: 6,
    },
    {
      label: 'System',
      name: 'deviceGroups',
      type: FieldType.MULTISELECT,
      data: '/api/systems',
      control: methods.control,
      span: 6,
    },
    {
      label: 'Recurrence (optional)',
      name: 'intervalValue',
      select: 'intervalType',
      type: FieldType.INTERVAL,
      control: methods.control,
      span: 6,
    },
    {
      label: 'Auto Mode Device (optional)',
      name: 'autoModeDeviceID',
      type: FieldType.TEXT,
      control: methods.control,
      // dataUrl: '/api/device/query',
      span: 6,
    },
    {
      label: 'Questions',
      name: 'questions',
      type: FieldType.DATAGRID,
      dataUrl: '/api/question/inspection/',
      dataIdField: 'InspectionTypeID',
      control: methods.control,
      fullWidth: true,
    },
  ];

  useEffect(() => methods.reset(state), [state]);

  useEffect(() => {
    if (id && id > 0) {
      get<InspectionType>(`/api/inspectionType/${id}`).then(setState);
    }
  }, [id]);

  useEffect(() => {
    methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  return (
    <FormProvider {...methods}>
      <form className='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {formSchema.map((field, key) => (
            <Grid key={key} item xs={12} sm={field.fullWidth ? 12 : field.span ?? 6} md={field.fullWidth ? 12 : field.span ?? 6}>
              <Field {...field} />
            </Grid>
          ))}
        </Grid>
        <Box component='div' sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2, mt: 2 }}>
          <Button type='button' variant='outlined' onClick={() => navigate('/types/inspection')}>
            cancel
          </Button>
          <Button type='submit' variant='contained'>
            Submit
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
