import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoCompleteFieldAttributes } from '.';

import logger from '../../utils/logger';
import { AxiosResponse } from 'axios';
import useAxios from 'hooks/useAxios';

export const AutoCompleteField: React.FC<AutoCompleteFieldAttributes> = ({ label, name, dataUrl, control }) => {
  const { get } = useAxios();
  const { register } = useFormContext();
  const [options, setOptions] = useState<string[]>([]);

  // const renderOptions = (response: AxiosResponse) => {
  //   logger.log('SELECT [%s] data:', label.toUpperCase(), response.data);
  //   setOptions(response.data);
  // };

  useEffect(() => {
    get<string[]>(dataUrl).then(setOptions);
  }, [dataUrl]);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        //rules={{ required: true }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            {...register(name)}
            freeSolo
            options={options}
            // value={selectedValue}
            style={{ width: '100%', margin: '5px' }}
            renderInput={(params) => <TextField {...params} label={label} />}
          />
        )}
      />
    </div>
  );
};
