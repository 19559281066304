import { FormControl, FormLabel, FormHelperText, Button, TextField, Select, MenuItem, Divider, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { useState } from 'react';
import { InspectionResult } from 'entities';
import { CameraAlt, Sensors } from '@mui/icons-material';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import { InspectionResultType } from 'views/Inspection';

type InspectionResultFormProps = {
  data?: { result: InspectionResult; type: InspectionResultType };
  editMode?: boolean;
  onSubmit: (result: InspectionResult) => void;
};

export const InspectionResultForm = ({ data, editMode, onSubmit }: InspectionResultFormProps) => {
  const [comment, setComment] = useState(editMode && data?.result ? (data?.type === 'Signal' ? data.result?.commentSignals : data?.result.commentVisibility ?? '') : '');
  const [outcome, setOutcome] = useState(editMode && data?.result ? (data?.type === 'Signal' ? data?.result?.outcomeSignals : data?.result.outcomeVisibility ?? 2) : 2);

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('Please submit a comment');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onSubmit && data?.result)
      data.type === 'Signal'
        ? onSubmit({ ...data.result, outcomeSignals: InspectionOutcome.FAIL, commentSignals: comment })
        : onSubmit({ ...data.result, outcomeVisibility: InspectionOutcome.FAIL, commentVisibility: comment });

    // logger.log('TestForm.handleSubmit(%s, %s, "%s")', signal?.id, outcome, comment);
    // axiosInstance
    //   .patch(`/api/signal/test?id=${signal?.id}&outcome=${outcome}&comment=${comment}`)
    //   .then((r) => {
    //     if (onSubmit) onSubmit(signal?.signalType?.name ?? '');
    //   })
    //   .catch((e) => logger.logError('FAILED handleTestPassed: ', e));

    // if (outcome === TestOutcome.PASS.toString()) {
    //   setHelperText('You got it!');
    //   setError(false);
    // } else if (outcome === TestOutcome.FAIL.toString()) {
    //   setHelperText('Sorry, wrong answer!');
    //   setError(true);
    // } else {
    //   setHelperText('Please select an option.');
    //   setError(true);
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl sx={{ m: 3, gap: '20px', width: '90%' }} variant='standard'>
        <Typography variant='h3'>{`${data?.type} check`}</Typography>
        <FormLabel sx={{ fontWeight: 700 }}>Device: {data?.result?.device?.name.replaceAll('_', ' ')}</FormLabel>
        <Divider />
        {/* <FormLabel id={'outcome-label'}>Outcome</FormLabel> */}
        <Select
          disabled={!editMode}
          //labelId="outcome-label"
          value={outcome}
          label='Outcome'
          onChange={(e) => setOutcome(parseInt(e.target.value.toString()))}
        >
          <MenuItem value={1}>{InspectionOutcome[0]}</MenuItem>
          <MenuItem value={2}>{InspectionOutcome[1]}</MenuItem>
        </Select>
        <TextField
          required={outcome === InspectionOutcome.FAIL}
          label='Comment'
          multiline
          rows={4}
          variant='standard'
          value={comment}
          error={error}
          onChange={(e) => setComment(e.target.value)}
        />
        <FormHelperText>{helperText}</FormHelperText>
        {data?.type === 'Visibility' && (
          <Button sx={{ mt: 1, mr: 1 }} variant='outlined' startIcon={<CameraAlt />}>
            Take Picture
          </Button>
        )}
        <Button disabled={!comment} sx={{ mt: 1, mr: 1 }} type='submit' variant='contained'>
          Submit
        </Button>
        {data?.type === 'Signal' && (
          <>
            <Divider />
            <FormLabel sx={{ fontWeight: 700 }}>Signal(s) raised</FormLabel>
            <List dense={true}>
              {data?.result?.signals?.map((s) => (
                <ListItem key={s.id}>
                  <ListItemIcon>
                    <Sensors />
                  </ListItemIcon>
                  <ListItemText primary={s.signalType?.name} secondary={s.address} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </FormControl>
    </form>
  );
};
