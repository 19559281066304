import { EditLocation } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemProps } from '..';

export const EditItem = ({ onClick }: MenuItemProps) => {
  return (
    <MenuItem disabled onClick={onClick}>
      <ListItemIcon>
        <EditLocation fontSize='small' />
      </ListItemIcon>
      <ListItemText>Edit marker</ListItemText>
    </MenuItem>
  );
};
