import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DateFieldAttributes } from '.';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField as MuiDateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';

export const DateField: React.FC<DateFieldAttributes> = ({ label, name, type, disabled, control, required, format }) => {
  const { register } = useFormContext();

  return (
    <section>
      <Controller
        name={name}
        control={control}
        //rules={{ required: required }}
        render={
          ({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MuiDateField
                {...field} //TODO implement
                {...register(name)}
                label={label}
                value={field.value ? dayjs(field.value) : null} //TODO format in get
                //disablePast={true}
                onChange={(newValue) => {
                  // const segments = newValue?.toString().split("-");
                  // if(segments?.length === 2){
                  //   const month = parseInt(segments[0]);
                  //   const year = parseInt(segments[1]);
                  //   if(!isNaN(year) && !isNaN(month))
                  //     field.value.inspectionDate = new Date(year, month, 1).toISOString();
                  // }
                }}
                //format={format}
                //required={required}
                style={{ width: '100%', margin: '5px' }}
                disabled={disabled}
              />
            </LocalizationProvider>
          )
          // <DateCalendar
          //     {...field}
          //     {...register(name)}
          //     defaultValue={dayjs(new Date())}
          //     views={['month', 'year']}
          //     // openTo="month"
          //     monthsPerRow={4}
          //     yearsPerRow={4}
          //     disablePast={true}

          //     // {...field}
          //     {...register(name)}

          //     //style={{ width: "100%", margin: "5px" }}
          //     //type={type}
          //     //label={label}
          //     //variant="outlined"
          //     onChange={() => {}}
          //     // format="MM/YYYY"
          //   />
        }
      />
    </section>
  );
};
