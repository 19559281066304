import { IconButton, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { renderProgress } from 'components/dataGrid/renderers/ProgressBar';
import InspectionConnector from 'connectors/InspectionConnector';
import { Inspection } from 'entities';
import useApp from 'hooks/useApp';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit, PlayArrow, Pause } from '@mui/icons-material';
import { InspectionState } from 'entities/enums/InspectionState';
import useInspection from 'hooks/useInspection';

type InspectionsTableProps = {
  hideFooter?: boolean;
  hideHeader?: boolean;
  pageSizeOptions?: number[];
};

function RenderActionCell(params: GridRenderCellParams<Inspection, any, any, GridTreeNodeWithRender>): ReactNode {
  const { handleState } = useInspection();
  const navigate = useNavigate();

  const handleEdit = (id: number) => {
    navigate(`/inspection/${id}`);
  };

  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton size='small' color='inherit' onClick={() => handleEdit(params.row.id)}>
        <Edit />
      </IconButton>
      <IconButton size='small' color='inherit' onClick={() => handleState(params.row.id)}>
        {params.row.state === InspectionState.Paused ? <PlayArrow /> : params.row.state === InspectionState.InProgress ? <Pause /> : <></>}
      </IconButton>
    </Stack>
  );
}

export default function InspectionsTable({ hideFooter, hideHeader }: InspectionsTableProps) {
  const apiRef = useGridApiRef();
  const { building } = useApp();
  const navigate = useNavigate();

  const { inspectionEvents } = InspectionConnector();

  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderActionCell,
    },
    {
      field: 'startDate',
      headerName: 'Start date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleDateString()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Inspection',
      filterable: false,
      width: 300,
    },
    {
      field: 'state',
      headerName: 'Status',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{InspectionState[parseInt(params.value)].toUpperCase()}</>;
      },
    },
    {
      field: 'progress',
      headerName: 'Progress',
      filterable: false,
      width: 200,
      renderCell: renderProgress,
    },
  ];

  useEffect(() => {
    inspectionEvents((inspection: Inspection) => {
      apiRef.current.updateRows([inspection]);
    });
  }, [inspectionEvents]);

  return (
    <DataGrid
      api={apiRef}
      hideFooter={hideFooter}
      hideHeader={hideHeader}
      orderBy={'date desc'}
      columnDefinitions={columns}
      dataUrl={`/api/inspection/query/${building?.id}`}
      disableRowSelectionOnClick={true}
    />
  );
}
